import { createContext } from "react";
import Logger from '../lib/logger'

export interface LoggerContextInterface {
  logger: Logger
}

const url = new URL(`/api/v1/logs`, process.env.REACT_APP_API_URL || window.location.origin)

export const defaultLoggerContext = {
  logger: new Logger({ url: url.href, sendCSPViolations: true })
}

export const LoggerContext = createContext<LoggerContextInterface>(defaultLoggerContext)