import React from 'react';
import Routes from './components/router/Routes/Routes';
import { compose } from 'recompose'

/* Hocs */
import { withAuth } from './hocs/withAuth';
import { withApi, withApiLoader } from './hocs/withApi';
import { withActionCable } from './hocs/withActionCable';
import { withLogger } from './hocs/withLogger';
import withIntl from './hocs/withIntl'
import withIonApp from './hocs/withIonApp';
import withBackgroundSurface from './hocs/withBackgroundSurface';
import withServiceWorker from './hocs/withServiceWorker';
import { withSuspense } from './hocs/withSuspense';
import { withIonReactRouter } from './hocs/withIonReactRouter';
import withErrorBoundary from './hocs/withErrorBoundary';
import withBigSurBackground from './hocs/withBigSurBackground';

const App: React.FC = () => {
  return (
    <Routes />
  );
}

const enhancer = compose(
  withIntl,
  withAuth,
  withApi,
  withActionCable,
  withLogger,
  withIonApp,
  withBigSurBackground,
  withServiceWorker,
  withSuspense,
  withIonReactRouter,
  withErrorBoundary,
  withApiLoader
)

export default enhancer(App);


