import { RouteComponentProps, useHistory } from "react-router";
import React, { useRef, useState, useEffect, useContext } from 'react'
import { IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonToast, IonLoading } from '@ionic/react';
import { FormattedMessage, IntlContext } from 'react-intl';
import { errorToString, htmlEncode } from '../../lib/helpers';
import Card from '../../components/common/Card/Card';
import styles from './ResetPassword.module.css'
import _get from 'lodash/get'
import useApi from "../../hooks/useApi";
import qs from 'qs'
import * as ROUTES from '../../constants/routes'

interface Props {

}

const ResetPassword: React.FC<Props & RouteComponentProps> = props => {
  const form = useRef<HTMLFormElement | null>(null)
  const [error, setError] = useState<string | null>(null)
  const handleErrorClose = () => setError(null)
  const codeInput = useRef<HTMLIonInputElement | null>(null)
  const passwordInput = useRef<HTMLIonInputElement | null>(null)
  const passwordConfirmInput = useRef<HTMLIonInputElement | null>(null)
  const api = useApi()
  const intl = useContext(IntlContext)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (query && codeInput.current) {
      codeInput.current.value = query.token ?.toString()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    resetPassword()
  }

  const resetPassword = async () => {
    const endpoint = `/api/v1/password/reset`
    const method = 'POST'

    if (passwordInput.current ?.value !== passwordConfirmInput.current ?.value) {
      window.alert(
        intl.formatMessage({
          id: 'reset-password.passwords-should-equals',
          defaultMessage: 'Password should be equal',
          description: 'Logout warning'
        })
      )
      if (passwordConfirmInput.current) passwordConfirmInput.current.value = ''
      return
    }

    const body = {
      token: codeInput.current ?.value,
      password: passwordInput.current ?.value
    }
    try {
      setIsLoading(true)
      const response = await api({ endpoint, method, body })
      console.log('password reset successful', response)
      history.replace(ROUTES.LOGIN)
    } catch (err) {
      const status = '' + _get(err, 'errors.0.status')
      switch (status) {
        case '404':
          setError('This link has expired.')
          break;

        default:
          setError(errorToString(err))
          break
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage
                id="generic.reset-password"
                defaultMessage="Reset Password"
                description="Reset Password message" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={styles.ionCardContent}>
            <form ref={form} action="/" className={styles.flex} onSubmit={handleSubmit}>
              
              <IonList>
                <IonItem class="ion-no-padding">
                  <IonLabel position="floating">
                    <FormattedMessage id="reset-password.code"
                      defaultMessage="Code"
                      description="Code label" />
                  </IonLabel>
                  <IonInput
                    name="code"
                    className={styles.input}
                    autocorrect="off"
                    autocomplete="on"
                    required={true}
                    ref={el => codeInput.current = el} />
                </IonItem>
                <IonItem class="ion-no-padding">
                  <IonLabel position="floating">
                    <FormattedMessage id="reset-password.new-password"
                      defaultMessage="New Password"
                      description="New password label" />
                  </IonLabel>
                  <IonInput
                    name="password"
                    className={styles.input}
                    autocorrect="off"
                    type="password"
                    autocomplete="on"
                    required={true}
                    ref={el => passwordInput.current = el} />
                </IonItem>
                <IonItem class="ion-no-padding">
                  <IonLabel position="floating">
                    <FormattedMessage id="reset-password.new-password-confirm"
                      defaultMessage="Confirm New Password"
                      description="New password confirm label" />
                  </IonLabel>
                  <IonInput
                    name="password-confirm"
                    className={styles.input}
                    autocorrect="off"
                    type="password"
                    autocomplete="on"
                    required={true}
                    ref={el => passwordConfirmInput.current = el} />
                </IonItem>
              </IonList>
                <IonRow className={styles.btns}>
                  <IonButton type="submit" color="primary" className={styles.btn} >
                    <FormattedMessage
                      id="reset-password.reset"
                      defaultMessage="Reset Password"
                      description="Reset Password label" />
                  </IonButton>
              </IonRow>
            </form>

          </IonCardContent>

          <IonLoading message={intl.formatMessage({ id: 'generic.loading-message' })} isOpen={isLoading} />

          <IonToast
            isOpen={!!error}
            onDidDismiss={handleErrorClose}
            message={htmlEncode(errorToString(error))}
            duration={10000}
            color="danger"
          />

        </Card>
      </IonContent>
    </IonPage>
  )
}


export default ResetPassword
