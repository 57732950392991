import { IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonSpinner, IonToast, IonToolbar, IonButton, IonIcon, IonBackButton, IonText, IonRow } from '@ionic/react';
import clsx from 'clsx';
import _get from 'lodash/get';
import Markdown from 'markdown-to-jsx';
import querystring from 'querystring';
import React, { useContext, useEffect, useRef, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { IntlContext } from 'react-intl';
import Card from '../../components/common/Card/Card';
import ErrorBoundary from '../../components/common/ErrorBoundary/ErrorBoundary';
import Overlay from '../../components/common/Overlay/Overlay';
import WorkflowStepContent from '../../components/router/WorkflowStepContent/WorkflowStepContent';
import * as ROUTES from '../../constants/routes';
import { errorToString, htmlEncode } from '../../lib/helpers';
import PushStep from '../../types/push-step';
import { WorkflowStepResponseDocument } from '../../types/workflow-step-response-document';
import styles from './WorkflowStep.module.css';
import qs from 'qs'
import { Redirect, useHistory } from 'react-router-dom'
import useWorkflowState from '../../hooks/useWorkflowState';
import StepHeaderImage from '../../components/workflow/StepHeaderImage/StepHeaderImage';
import useApi from '../../hooks/useApi';
import WorkflowStepButton from '../../types/workflow-step-button';
import WorkflowButton from '../../components/workflow/WorkflowButton/WorkflowButton';
import { chevronBackOutline } from 'ionicons/icons';
import DumpJson from '../../components/common/DumpJson/DumpJson';
import useStepActionTrigger from '../../hooks/useStepActionTrigger';
import CardFooter from '../../components/common/CardFooter/CardFooter';

const defaultFavicon = '/assets/icon/favicon.png'

const hideStepsWhenValueIsNull = ['inferFromPreviousSubmission', 'inferFromPreviousTrainSubmission', 'inferTrainSubmissionFromOfflineStorage']

const parseOptions = (search: string) => {
  const query = querystring.parse(search.replace(/^\?/, ''))
  const options = {
    focus: query.focus === 'true'
  }
  return options
}

const WorkflowStep: React.FC = props => {
  const location = _get(props, 'location')
  const match = _get(props, 'match')
  const { workflowResultId, redirectToWorkflowResult } = useWorkflowState()
  const variation = _get(props, 'match.params.variation')
  const scope = _get(props, 'match.params.step', '').split('/')
  let stepId = ''
  for (let i = 0; i < scope.length; i += 2) {
    stepId = scope[i]
  }

  const options = parseOptions(_get(props, 'location.search', ''))

  const [workflowDocument, setDocument] = useState<null | WorkflowStepResponseDocument>(null)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useContext(IntlContext)
  const ionPageRef = useRef()
  const [redirect, setRedirect] = useState('')
  const history = useHistory()
  const workflowState = useWorkflowState()
  const step = _get(workflowDocument, 'data.attributes.step')
  const footer = _get(workflowDocument, 'data.attributes.step.footer', '')
  const toolbarObj = _get(workflowDocument, 'data.attributes.step.toolbar')
  const api = useApi()

  const pushStep: PushStep = (id, options?) => {
    if (id === null || id === undefined) {
      window.alert(intl.formatMessage({
        id: 'application-error.invalid-step',
        description: 'Invalid application step message',
        defaultMessage: `Application error: 
This step has not been configured properly, there is no valid action attached to this button. 
Please contact technical support.`
      }))
      console.error('application-error.invalid-step')
      return
    }
    const params = _get(options, 'params')
    const focus = _get(options, 'focus') ? true : undefined
    let q = qs.stringify({ focus, params }, { encode: false })
    if (q) q = '?' + q
    const route = ROUTES.WORKFLOW_STEP
      .replace(':variation', variation)
      .replace(':id', '' + workflowResultId)
      .replace(':step', id)
    _get(props, 'history').push(route + q, options)
  }

  const truncatedStep = stepId.split('[')[0]

  const onFinish = async (value: any) => {
    setIsLoading(true)
    try {
      const url = new URL(`/api/v1/workflow-results/${'' + workflowResultId}/steps/${stepId}`, process.env.REACT_APP_API_URL || window.location.origin)
      const response = await window.fetch(url.href, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            value,
            header: workflowDocument!.data.attributes.step.header,
            main: workflowDocument!.data.attributes.step.main,
            name: workflowDocument!.data.attributes.step.name
          },
        })
      })
      const { errors, ...document } = await response.json()
      if (!response.ok) {
        throw errors[0]
      }
      setDocument(document)
      return document
    }
    finally {
      setIsLoading(false)
    }
  }

  const timeout = useRef<any>()
  const markAsFinished = async () => {
    const endpoint = `/api/v1/workflow-results/${workflowResultId}/finish`
    const method = 'POST'
    await api({ endpoint, method })
  }

  const handleStepLoad = async ([res, json]: [Response, WorkflowStepResponseDocument]) => {
    if (res.status !== 200) throw json
    const variationId = _get(json, 'data.relationships.workflowVariation.data.id')
    if (variationId !== variation) {
      workflowState.setWorkflowResultId(null)
      return
    }
    const redirect = _get(json, 'data.attributes.step.redirect')
    const timeoutTime = _get(json, 'data.attributes.step.timeout')
    const isLastOne = !_get(json, 'data.relationships.up.data.id') && !_get(json, 'data.relationships.next.data.id')
    const done = _get(json, 'data.attributes.step.done') === true
    const completed = _get(json, 'data.attributes.completed') === true
    const isDone = isLastOne || done
    if (isDone && !completed) {
      try {
        await markAsFinished()
        window.localStorage.setItem('previous-workflow-result-id', '' + workflowResultId)
      } catch (err) {
        console.error(err)
        setError('Failed to submit this form, please contact staff... ' + errorToString(err))
        return
      }
    }
    if (timeoutTime) {
      const route = ROUTES.WORKFLOW
        .replace(':variation', variation)
      timeout.current = setTimeout(() => {
        // history.replace(route)
        // hotfix
        window.location.href = route
      }, +timeoutTime)
    }

    const shouldHide = hideStepsWhenValueIsNull.includes(_get(json, 'data.attributes.step.input'))
      && _get(json, 'data.attributes.value.submission') == null

    if (redirect) {
      const route = ROUTES.WORKFLOW_STEP
        .replace(':variation', variation)
        .replace(':id', '' + workflowResultId)
        .replace(':step', redirect)
      setRedirect(route)
    } else if (shouldHide) {
      const route = ROUTES.WORKFLOW_STEP
        .replace(':variation', variation)
        .replace(':id', '' + workflowResultId)
        .replace(':step', _get(json, 'data.attributes.step.cancel.id'))
      setRedirect(route)
    } else {
      setDocument(json)
    }
    setError(null)
    setIsLoading(false)
  }

  const handleStepLoadRef = useRef(handleStepLoad)
  handleStepLoadRef.current = handleStepLoad

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current)
    if (!workflowResultId) return
    const locationQuery = qs.parse(location.search.replace('?', ''))
    const previousWorkflowResultId = window.localStorage.getItem('previous-workflow-result-id')
    const queryObject = {
      ...locationQuery,
      params: {
        ...(locationQuery.params as qs.ParsedQs),
        previousWorkflowResultId
      }
    }
    let q = qs.stringify(queryObject, { encode: false })
    if (q) q = '?' + q
    const url = new URL(`/api/v1/workflow-results/${workflowResultId}/steps/${stepId}${q}`, process.env.REACT_APP_API_URL || window.location.origin)
    setIsLoading(true)
    setDocument(null)
    setRedirect('')
    window.fetch(url.href)
      .then(async res => [res, await res.json()])
      .then(async ([res, json]) => handleStepLoadRef.current([res, json]))
      .catch(error => {
        setDocument(null)
        setError(error)
        console.error(error)
        setIsLoading(false)
      })

    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId, workflowResultId, ionPageRef])

  const errorStr = errorToString(error)

  const icon = workflowDocument ?.data.attributes.icon
  let faviconLink = <link rel="icon" type="image/png" href={defaultFavicon} />
  if (icon && icon.toString().endsWith('.png')) faviconLink = <link rel="icon" type="image/png" href={icon} />
  if (icon && icon.toString().endsWith('.jpg')) faviconLink = <link rel="icon" type="image/jpeg" href={icon} />
  if (icon && icon.toString().endsWith('.jpeg')) faviconLink = <link rel="icon" type="image/jpeg" href={icon} />
  if (icon && icon.toString().endsWith('.ico')) faviconLink = <link rel="icon" type="image/x-icon" href={icon} />
  if (icon) faviconLink = <link rel="icon" href={icon} />

  const buttonsLeft = _get(toolbarObj, 'buttonsLeft', [])
  const buttonsRight = _get(toolbarObj, 'buttonsRight', [])
  const title = '' + _get(toolbarObj, 'title', '')

  const workflowAction = useStepActionTrigger()

  const renderToolbarBtn = (btn: WorkflowStepButton, key: number) => {
    if (btn.back) {
      return <WorkflowButton
        key={key}
        icon={<IonIcon icon={chevronBackOutline} />}
        iconOnly={true}
        button={btn}
        onClick={() => workflowDocument && workflowAction(workflowDocument, btn.action, pushStep)}
      />
    }
    switch (_get(btn, 'action.type')) {
      case 'historyBack':
      case 'back': {
        return <WorkflowButton
          key={key}
          icon={<IonIcon icon={chevronBackOutline} />}
          iconOnly={true}
          button={btn}
          onClick={() => workflowDocument && workflowAction(workflowDocument, btn.action, pushStep)} />
      }
      default: {
        return <WorkflowButton key={key} button={btn} onClick={() => workflowDocument && workflowAction(workflowDocument, btn.action, pushStep)} />
      }
    }
  }

  const toolbarJsx = toolbarObj && {
    buttonsLeft: Array.isArray(buttonsLeft) && buttonsLeft.map(renderToolbarBtn),
    buttonsRight: Array.isArray(buttonsRight) && buttonsRight.map(renderToolbarBtn),
    title: title
  }

  const footerJsx = footer && <Markdown>{footer}</Markdown>

  return (
    <IonPage className={clsx(styles.page, 'page')} ref={ionPageRef}>
      {redirect && <Redirect key={redirect + ' ' + stepId + ' ' + workflowResultId} to={redirect} />}
      <Helmet>
        {faviconLink}

        <title>{isLoading ? intl.formatMessage({
          id: 'generic.loading-message',
          description: 'A generic default loading message',
          defaultMessage: 'Loading...'
        }) : _get(workflowDocument, 'data.attributes.name') || errorStr}</title>

        <meta name="description" content={isLoading ? intl.formatMessage({
          id: 'generic.loading-message',
          description: 'A generic default loading message',
          defaultMessage: 'Loading...'
        }) : _get(workflowDocument, 'data.attributes.step.main') || errorStr} />


        <link rel="icon" type="image/png" href={_get(workflowDocument, 'data.attributes.icon') || defaultFavicon} />
      </Helmet>

      <IonContent className={clsx(
        styles.ionContent,
        `workflow-content-step-parent-${truncatedStep}`,
        "workflow-content",
        `workflow-content-step-${workflowDocument ?.data.attributes ?.step ?.id}`,
        `workflow-content-input-${workflowDocument ?.data.attributes ?.step ?.input}`
      )}>
        <IonToast
          isOpen={!!error}
          onDidDismiss={() => setError(null)}
          message={htmlEncode(errorStr)}
          color="danger"
          duration={10000}
        />

        <Card className="workflow-card-wrapper" toolbar={toolbarJsx} footer={footerJsx}>
          <IonCardHeader className="workflow-card-header">
            <StepHeaderImage image={_get(workflowDocument, 'data.attributes.step.headerImage')} />
            <IonCardTitle className={clsx(styles.ionCardTitle, 'workflow-card-title')}>
              <Markdown>{_get(workflowDocument, 'data.attributes.step.header') || ''}</Markdown>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="workflow-card-content">
            <ErrorBoundary>
              <Overlay
                className={clsx(styles.overlay, "workflow-card-overlay")}
                overlayClassName={styles.overlayElement}
                active={isLoading}
                overlay={<IonSpinner className={clsx(styles.spinner, "workflow-overlay-spinner")} name="crescent" />}>
                <Suspense fallback={<IonSpinner className={clsx(styles.spinner, "workflow-overlay-spinner")} name="crescent" />}>
                  {workflowDocument != null
                    && workflowDocument.data.id === stepId
                    && <WorkflowStepContent
                      match={match}
                      scope={scope}
                      location={location}
                      options={options}
                      pushStep={pushStep}
                      onFinish={onFinish}
                      workflowResultId={'' + workflowResultId}
                      document={workflowDocument} />
                  }
                </Suspense>
              </Overlay>
            </ErrorBoundary>
          </IonCardContent>
        </Card>
      </IonContent>
    </IonPage>
  )
};

export default WorkflowStep
