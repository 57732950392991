import { setupConfig, isPlatform } from '@ionic/core'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
/* Prevent navigation with backspace */
/* Theme variables */
import '../theme/variables.css';
import '../theme/style.css'

setupConfig({
  mode: 'ios'
})

setTimeout(() => {
  (window as any).isPlatform = isPlatform
}, 200)

if (isPlatform('hybrid') && isPlatform('ios')) {
  if (window.location.pathname === '/') window.location.pathname = '/admin'
}

export default true
