import { IonSpinner } from "@ionic/react";
import React, { useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router";
import { LOGIN } from '../../../constants/routes';
import { AuthContext } from "../../../contexts/auth";

interface ProtectedRouteProps<T> {
  component: React.ComponentType<RouteComponentProps<any> & T>,
  path: string,
  childProps?: T
}

export function ProtectedRoute<T extends object> ({ component, path, childProps, ...other }: ProtectedRouteProps<T>) {

  const ProtectedComponent = component

  const context = useContext(AuthContext)

  const render = (props: any) => 
    context.isAuthenticated
      ? <ProtectedComponent {...props} {...childProps} />
      : context.oauthLoading
        ? <IonSpinner name="crescent" style={{ margin: 'auto' }} />
        : <Redirect to={`${LOGIN}?redirect=${encodeURIComponent(`${props.location.pathname}${props.location.search}${props.location.hash}`)}`} />

  return (
    <Route
      path={path}
      render={render}
      {...other}
    />
  );
}