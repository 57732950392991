import { useEffect, useRef } from "react";
import { matchShortcut } from "../lib/button-shortcuts";

type ListItem = React.MutableRefObject<{
  shortcut: string | number;
  onTrigger: (e: KeyboardEvent) =>  any;
  priority: number
}>

const listeners: ListItem[] = []

window.addEventListener('keydown', async e => {
  for (let i = 0; i < listeners.length; i++) {
    const listener = listeners[i];
    const code = e.keyCode
    const shortcut = listener.current.shortcut
    const isMatch = typeof shortcut === 'number'
      ? code === shortcut
      : !!e.key && matchShortcut(shortcut, e)
    if (isMatch) {
      let r = await listener.current.onTrigger(e)
      if (r === 'CANCEL') break;
    }
  }
})

// Hook
function useShortcut(shortcut: string | number, onTrigger: (e: KeyboardEvent) => any, priority = 0) {
  const references = useRef({ shortcut, onTrigger, priority })
  references.current.shortcut = shortcut
  references.current.onTrigger = onTrigger

  useEffect(() => {
    listeners.push(references)
    listeners.sort((a1, a2) => a2.current.priority - a1.current.priority)
    return () => {
      listeners.splice(listeners.indexOf(references), 1)
    }
  }, [])
}

export default useShortcut