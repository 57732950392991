import React from 'react';
import { IonReactRouter } from '@ionic/react-router';

export function withIonReactRouter<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithIonReactRouter = (props: P) => {
    return <IonReactRouter>
      <WrappedComponent  {...props} />
    </IonReactRouter>
  };
  return ComponentWithIonReactRouter;
}

