import React, { useEffect, memo } from 'react';
import styles from './Phone.module.css';
import useLongPress from '../../../hooks/useLongPress';

type Props = {
  onChange: (value: string) => void,
  value: string,
  format?: string,
  maxDigits?: number
}

const Phone: React.FC<Props> = memo((props) => {

  const { maxDigits = Infinity, onChange } = props

  const translations = {}
  const characters = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const subCharacters = [' ', 'abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz']

  const digits = props.value.replace(/[^0-9]/g, "")

  const handleClick = (character: string | number) => {
    const newStr = props.value + (character in translations ? (translations as any)[character] : character)
    onChange(newStr)
  }

  const backspace = () => {
    const newStr = props.value.slice(0, -1)
    onChange(newStr)
  }

  useEffect(() => {
    const backspaceEventListener = (e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        const newStr = props.value.slice(0, -1)
        onChange(newStr)
      }
      else if (e.key === 'Delete') {
        const newStr = props.value.slice(0, -1)
        onChange(newStr)
      }
      else if (e.key === '+') {
        const newStr = props.value + e.key
        onChange(newStr)
      }
      else if (/[0-9]/.test(e.key)) {
        const newStr = props.value + e.key
        onChange(newStr)
      }
    }
    window.addEventListener('keydown', backspaceEventListener)
    return () => window.removeEventListener('keydown', backspaceEventListener)
  }, [props.value, onChange]);

  const handleLongPressOnZeroButton = (e: React.TouchEvent<Element> | React.MouseEvent<Element>) => {
    handleClick('+')
  }

  const handleClickOnZeroButton = (e: React.TouchEvent<Element> | React.MouseEvent<Element>) => {
    handleClick('0')
  }

  const events = useLongPress(handleLongPressOnZeroButton, handleClickOnZeroButton)

  return (
    <div className={styles.root}>
      {characters.map((c, index) => {
        return (
          <div className={styles.col} key={c}>
            {
              c !== undefined ? (
                <button
                  disabled={digits.length >= maxDigits}
                  className={styles.btn}
                  onClick={() => handleClick(c)}>
                  <span className={styles.character}>
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 160 90"
                      preserveAspectRatio="xMinYMid meet"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <text
                        x="80"
                        y="83"
                        textAnchor="middle"
                        fontSize="110"
                        fill="black"
                      >{c}</text>
                    </svg>
                  </span>
                  <br />
                  <span className={styles.subCharacter}>
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 280 90"
                      preserveAspectRatio="xMinYMid meet"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <text
                        x="140"
                        y="45"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fontSize="90"
                        fill="black"
                      >{subCharacters[index]}</text>
                    </svg>
                  </span>
                </button>
              ) : null
            }
          </div>
        )
      })}
      <div className={styles.col} />
      <div className={styles.col}>
        {
          <button
            {...events}
            disabled={digits.length >= maxDigits}
            className={styles.btn}>
            <span className={styles.character}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 160 90"
                preserveAspectRatio="xMinYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <text
                  x="80"
                  y="83"
                  textAnchor="middle"
                  fontSize="110"
                  fill="black"
                >0</text>
              </svg>
            </span>
            <br />
            <span className={styles.subCharacter}>
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 280 90"
                preserveAspectRatio="xMinYMid meet"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <text
                  x="140"
                  y="45"
                  alignmentBaseline="middle"
                  textAnchor="middle"
                  fontSize="90"
                  fill="black"
                >+</text>
              </svg>
            </span>
          </button>
        }
      </div>
      <div className={styles.backspaceCol}>
        {props.value.length > 0 ? (
          <button
            className={styles.backspaceBtn}
            onClick={backspace}>
            <span className={styles.character}>
              <svg width="100%" height="100%" viewBox="0 0 187 100" xmlns="http://www.w3.org/2000/svg">
                <rect className={styles.backspaceFill} rx="15" id="svg_1" height="100" width="150" y="0" x="36.73815" />
                <rect className={styles.backspaceFill} transform="rotate(-45 50.975070953369126,50.156833648681626) " rx="15" height="79.364263" width="79.364263" y="10.474713" x="11.293017" />
                <g>
                  <rect className={styles.backspaceCrossFill} rx="2" transform="matrix(0.8270212399250527,0.8270212399250527,-0.8270212399250527,0.8270212399250527,63.34005303638803,-91.9451886211742)" height="80" width="4" y="17.787974" x="111.846292" />
                  <rect className={styles.backspaceCrossFill} rx="2" transform="matrix(-0.8270212399250525,0.8270212399250527,-0.8270212399250527,-0.8270212399250525,256.7345551158354,-8.841625716727094)" height="80" width="4" y="13.318763" x="122.467627" />
                </g>
              </svg>
            </span>
            <br />
            <span className={styles.subCharacter} /></button>) : null}
      </div>
    </div>
  )
}, (prevProps, nextProps) => prevProps.format !== nextProps.format || prevProps.maxDigits !== nextProps.maxDigits)

export default Phone;
