
const askForConfirmation = false

export const bustCache = () => {
  if (!askForConfirmation || window.confirm('Bust all cache?')) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        try {
          registration.unregister()
        } catch (err) {
          console.error(err)
        }
      }
    }).catch(function (err) {
      window.alert(err)
    }).then(function () {
      return (window.indexedDB as any).databases()
    }).then(function (r) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      let cookies = window.document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
      for (let i = 0; i < r.length; i++)
        window.indexedDB.deleteDatabase(r[i].name);
      window.location.reload(true)
    }).catch(err => {
      window.alert(err)
    })
  }
}
