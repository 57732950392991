
const preventBackspaceNavigation = () => {
  var rx = /INPUT|SELECT|TEXTAREA/i;
  const onKey = (e: KeyboardEvent) => {
    if (e.which === 8) { // 8 == backspace
      const target = e.target as any
      if (!rx.test(target.tagName) || target.disabled || target.readOnly) {
        e.preventDefault();
      }
    }
  }
  document.addEventListener('keydown', onKey)
  document.addEventListener('keypress', onKey)
}

export default preventBackspaceNavigation
