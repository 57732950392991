import { RouteComponentProps } from "react-router";
import React, { useState } from 'react'
import { IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage, IonToast } from '@ionic/react';
import { FormattedMessage } from 'react-intl';
import { errorToString, htmlEncode } from '../../lib/helpers';
import Card from '../../components/common/Card/Card';
import styles from './ForgotPasswordDone.module.css'
import qs from 'qs'

interface Props {

}

const ForgotPasswordDone: React.FC<Props & RouteComponentProps> = props => {
  const [error, setError] = useState<string | null>(null)
  const handleErrorClose = () => setError(null)

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  const email = query.email || ''

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage
                id="generic.reset-password"
                defaultMessage="Reset Password"
                description="Reset Password message" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={styles.ionCardContent}>
            
            <h2>Please check the inbox of your {email} account!</h2>
            
          </IonCardContent>

          <IonToast
            isOpen={!!error}
            onDidDismiss={handleErrorClose}
            message={htmlEncode(errorToString(error))}
            duration={10000}
            color="danger"
          />

        </Card>
      </IonContent>
    </IonPage>
  )
}


export default ForgotPasswordDone
