import messagesEn from "../../translations/en.json";

export const messages = {
    en: messagesEn
};

export const navigatorLanguage = navigator.language.split(/[-_]/)[0];
export const language = navigatorLanguage in messages ? navigatorLanguage : 'en'

export const formatDate = (date: any = undefined, delimiter = '-') => {
  const dateObj = date !== undefined && date !== null ? new Date(date) : new Date()
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return year + delimiter + month + delimiter + day;
}

