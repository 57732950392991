import React from 'react';
import ErrorBoundary from '../components/common/ErrorBoundary/ErrorBoundary';

export default function withErrorBoundary<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithErrorBoundary = (props: P) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  };
  return ComponentWithErrorBoundary;
}

