import './eval-profiler'
import '../lib/browser-check/browser-check'
import './polyfills'
import './ionic'
import { disableIosDoubleTapZoom } from '../lib/helpers';
import preventBackspaceNavigation from '../lib/prevent-backspace-navigation';

// disable backspace navigation on firefox
preventBackspaceNavigation()

// in addition to user-scalable=no this restricts zooming
disableIosDoubleTapZoom()

export default true