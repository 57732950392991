import { createContext, Dispatch } from "react";

export type Action =
  | { type: 'fetchAuthTokenSuccess', oauth: AuthDefaultContext["oauth"], rememberMe: boolean }
  | { type: 'fetchAuthTokenFailed', error: Error }
  | { type: 'fetchAuthTokenLoading' }
  | { type: 'logout' }


export interface OAuth {
  access_token: string,
  token_type: 'Bearer',
  expires_in: number,
  refresh_token: string,
  created_at: number
}

export interface AuthDefaultContext {
  initialized: boolean
  isAuthenticated: boolean,
  clientId: string,
  oauth: OAuth | null,
  oauthLoading: boolean,
  oauthError: null | Error,
  dispatch: Dispatch<Action>,
  refresh: () => Promise<OAuth | null>
}

export const defaultAuthContext = {
  initialized: false,
  isAuthenticated: false,
  clientId: 'eallukrLCD85dFNRxcn60gJk2kwQlNIbQU_6iSpV1wU',
  dispatch: () => { },
  oauthLoading: false,
  oauth: null,
  oauthError: null,
  refresh: async () => null
}

export const AuthContext = createContext<AuthDefaultContext>(defaultAuthContext);
