import React from 'react';
import useIsDark from '../hooks/useIsDark';
import clsx from 'clsx';

export default function withBigSurBackground<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithBigSur = (props: P) => {
    const isDark = useIsDark()
    return (
      <>
        <div className={clsx('big-sur-background default-surface', isDark && 'big-sur-dark-background')} />
        <WrappedComponent {...props} />
      </>
    )
  };
  return ComponentWithBigSur;
}

