import WorkflowStepButton from '../types/workflow-step-button';
import memoize from 'lodash/memoize'

const shortcutSeperator = /[+, \t-]/

const matchShortcutExtraKey = (key: string, e: KeyboardEvent) => {
  switch (key.toLowerCase()) {
    case 'ctrl':
      return e.ctrlKey
    case 'shift':
      return e.shiftKey
    case 'meta':
    case 'command':
    case 'cmd':
      return e.metaKey
    case 'option':
    case 'opt':
    case 'alt':
      return e.altKey
  }
}

export const matchParsedShortcut = (key: string, extraKeys: string[], e: KeyboardEvent) => {
  key = key.toLowerCase()
  let matchKey = e.key.toLowerCase()
  if (key !== matchKey) {
    matchKey = e.code && e.code.replace('Key', '').replace('Digit', '').toLowerCase()
    if (key !== matchKey) return false
  }
  const extraKeysAllMatch = extraKeys.every(extraKey => matchShortcutExtraKey(extraKey, e))
  if (extraKeysAllMatch) {
    e.preventDefault()
  }
  return extraKeysAllMatch
}

export const parseShortcut = memoize((shortcut: string) => {
  const allKeys = shortcut.toLowerCase().split(shortcutSeperator)
  const key = allKeys[allKeys.length - 1]
  const extraKeys = allKeys.slice(0, -1)
  return { key, extraKeys }
})

export const matchShortcut = (shortcut: string, e: KeyboardEvent) => {
  const parsed = parseShortcut(shortcut)
  if (!parsed) return false
  return matchParsedShortcut(parsed.key, parsed.extraKeys, e)
}

const matchShortcuts = (btn: WorkflowStepButton, e: KeyboardEvent) => {
  let shortcuts: string[]
  if (typeof btn.shortcut === 'string') shortcuts = [btn.shortcut]
  if (Array.isArray(btn.shortcut)) shortcuts = btn.shortcut.map(x => x && x.toString())
  else return false
  return shortcuts.some(shortcut => matchShortcut(shortcut, e))
}

export const matchButtonShortcuts = (buttons: WorkflowStepButton[], e: KeyboardEvent) =>
  buttons.find(btn => matchShortcuts(btn, e))



