import { createContext } from "react";
import { Cable } from 'actioncable'

export interface ActionCableContext {
  cableApp: Cable | null
}

const defaultActionCable = {
  cableApp: null
}

export const ActionCableContext = createContext<ActionCableContext>(defaultActionCable)
