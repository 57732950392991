
import { useContext } from "react";
import { APIContext } from "../contexts/api";

// Hook
function useApi() {
  return useContext(APIContext).api
}

export default useApi
