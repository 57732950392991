import React from 'react';
import { IntlProvider } from "react-intl";
import _get from 'lodash/get';
import { language, messages } from '../lib/intl';

export default function withIntl<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithIntl = (props: P) => {
    return (
      <IntlProvider locale={language} messages={_get(messages, language)}>
        <WrappedComponent {...props} />
      </IntlProvider>
    )
  };
  return ComponentWithIntl;
}

