import { useEffect, useRef, RefObject } from "react";
import { focus } from "../lib/helpers";


export const useFocusAndAutofill = (inputRef: RefObject<null | undefined | HTMLIonInputElement>, value: string, setValue: (value: string) => void) => {
  const references = useRef({ value, setValue })
  references.current.value = value
  references.current.setValue = setValue

  useEffect(() => {
    const interval = setInterval(() => {
      if (inputRef.current) {
        focus(inputRef.current)
        inputRef.current.getInputElement().then(input => {
          if (input.value !== references.current.value) setValue(input.value)
        })
        clearInterval(interval)
      }
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const useFocus = (input: HTMLIonInputElement | null | undefined) => {
  const references = useRef({ input })
  references.current.input = input

  useEffect(() => {
    const interval = setInterval(() => {
      if (references.current.input) {
        focus(references.current.input)
        clearInterval(interval)
      }
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
