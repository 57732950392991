import { IonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { UPDATE_SW_IMMEDIATELY, DISABLE_SW } from '../constants/config';
import * as serviceWorker from '../serviceWorker';

export default function withServiceWorker<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithServiceWorker = (props: P) => {
    const [updateRegistration, setShowUpdateToast] = useState<ServiceWorkerRegistration | void>()

    const update = () => {
      if (updateRegistration && updateRegistration.waiting) {
        updateRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload(true);
    }

    const updateServiceWorkerImmediate = (updateRegistration: ServiceWorkerRegistration) => {
      const registrationWaiting = updateRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', e => {
          if ((e as any).target.state === 'activated') {
            window.location.reload(true);
          }
        });
      }
    };

    useEffect(() => {
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      if (DISABLE_SW) {
        serviceWorker.unregister();
      } else {
        serviceWorker.register({
          onUpdate: registration => {
            // Do instant update
            if (UPDATE_SW_IMMEDIATELY) {
              updateServiceWorkerImmediate(registration)
            } else {
              setShowUpdateToast(registration)
            }
          }
        });
      }
    }, [])

    const dismissToast = () => setShowUpdateToast()

    return (
      <>
        <WrappedComponent {...props} />
        <IonToast
          isOpen={!!updateRegistration}
          cssClass="update-service-worker-update-toast"
          onDidDismiss={dismissToast}
          message="A new version of the app is available!"
          duration={30000}
          position="top"
          buttons={[
            {
              text: 'Update Now',
              handler: () => {
                console.log('User choose to update this app.')
                update()
              }
            }
          ]}
        />
      </>
    )
  };
  return ComponentWithServiceWorker;
}

