import clsx from 'clsx';
import { EventEmitter } from 'events';
import debounce from 'lodash/debounce';
import React, { useEffect, useRef } from 'react';
import styles from './Overlay.module.css';

const documentSize = new EventEmitter()
const changeDocumentSize = debounce(() => documentSize.emit('change'), 100)
window.addEventListener("resize", changeDocumentSize);
window.addEventListener("orientationchange", changeDocumentSize);

type Props = {
  overlay: React.ReactNode,
  active: boolean,
  className?: string
  overlayClassName?: string,
  [key: string]: any
}

const Overlay: React.FC<Props> = ({ children, overlay, active, overlayClassName, className, ...rest }) => {

  const refContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  const refreshHeight = () => {
    // const height = typeof refContainer.current!.style.height === 'string' ? +refContainer.current!.style.height.replace('px', '') : 0
    // refContainer.current!.setAttribute('data-height', '' + height)
    // if (refContainer.current) refContainer.current.style.minHeight = ''
    // if (refContainer.current) refContainer.current.style.minHeight = refContainer.current.offsetHeight + 'px'
    
  }

  useEffect(() => {
    refreshHeight()
  }, [])

  useEffect(() => {
    documentSize.addListener('change', refreshHeight)
    return () => {
      documentSize.removeListener('change', refreshHeight)
    }
  })

  return (
    <div {...rest} ref={refContainer} className={clsx(styles.Overlay, className)}>
      {children}
      <div className={clsx(styles.overlayPanel, overlayClassName, active && styles.active)}>
        {overlay}
      </div>
    </div>
  );
}

export default Overlay;
