import React, { useContext, useEffect, useState, useRef } from 'react';
import { api, APIContext } from '../contexts/api';
import { AuthContext } from '../contexts/auth';
import { AppPreferences } from '@ionic-native/app-preferences';
import useApiHost from '../hooks/useApiHost';
import { IonLoading } from '@ionic/react';
import { useIntl } from 'react-intl';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

type API = typeof api

const isInvalidToken = (error: any) =>
  'errors' in error
  && Array.isArray(error.errors)
  && error.errors.some((err: any) =>
    'code' in err
    && err.code === 'invalid_token')

let _apiHost: string | null = null

const getApiHost = async () => {
  _apiHost = process.env.REACT_APP_API_URL 
    || await AppPreferences.fetch('API_HOST')
    || process.env.PUBLIC_URL
  return _apiHost as string
}

export const apiHostPromise = getApiHost()

export function withApi<P>(
  WrappedComponent: React.ComponentType<P>,
  timeout = 30000
) {
  const ComponentWithAPI = (props: P) => {
    const auth = useContext(AuthContext)
    const [apiHost, setApiHost] = useState(_apiHost)

    useEffect(() => {
      apiHostPromise.then(() => {
        if (_apiHost !== apiHost) setApiHost(_apiHost)
      })
    }, [apiHost])

    const func: API = async args => {
      const authToken = args.accessToken && await auth.refresh()
      try {
        const host = apiHost ? apiHost : await apiHostPromise
        return await Promise.race([
          api(args, authToken, host),
          new Promise((_, reject) => setTimeout(
            () => reject(new Error('request timeout')),
            args.timeout || timeout
          ))
        ])
      } catch (err) {
        if (args.accessToken && isInvalidToken(err)) {
          console.warn('invalid token')
          auth.dispatch({ type: 'logout' })
        }
        throw err
      }
    }

    return <APIContext.Provider value={{ api: func, apiHost }}>
      <WrappedComponent  {...props} />
    </APIContext.Provider>
  };
  return ComponentWithAPI;
}

export function withApiLoader<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithAPILoader = (props: P) => {
    const host = useApiHost()
    const intl = useIntl()

    if (host === null) {
      return <IonLoading message={intl.formatMessage({ id: 'generic.loading-message' })} isOpen={true} />
    } else {
      return <WrappedComponent  {...props} />
    }
  }

  return ComponentWithAPILoader
}
