import _get from 'lodash/get'
import _identity from 'lodash/identity'
import get from 'lodash/get'

export const errorToString = (error: any) => {
  if (typeof error === 'string') return error
  if (typeof error === 'object' && error != null && 'errors' in error) {
    return error.errors.map(errorToString).join('\n')
  }
  let status = _get(error, 'status')
  if (status) status = 'http status code: ' + status
  const code = _get(error, 'code')
  const title = _get(error, 'title')
  const detail = _get(error, 'detail', _get(error, 'message'))

  return [title, detail, code, status].filter(_identity).join(', ')
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const replaceAll = (self = '', substr = '', newSubstr = '') => {
  while(self.indexOf(substr) > 0) self = self.replace(substr, newSubstr)
  return self
}

export const seriesFormat = (self: string[], joinStr = '', seperator = ', ', combiner = ' and ') => {
  if (self.length === 0) return ''
  if (self.length === 1) return self.join(seperator)
  return self.slice(0, -1).join(seperator) + combiner + joinStr + self[self.length - 1]
}

export const isTouchDevice = () => {

  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  var mq = function (query: string) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window) || ((window as any).DocumentTouch && document instanceof (window as any).DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const focus = async (ionInput: HTMLIonInputElement, x = 0, y = 0) => {
  if (!ionInput) return
  const touchstart = (e: TouchEvent) => {
    input.focus()
  }
  const input = await ionInput.getInputElement()
  input.addEventListener('touchstart', touchstart)
  let e: any;
  if (isTouchDevice()) {
    try {
      e = document.createEvent('TouchEvent')
      e.initTouchEvent("touchstart", true, true)
    }
    catch (err) {
      try {
        e = document.createEvent('UIEvent')
        e.initUIEvent("touchstart", true, true)
      }
      catch (err) {
        e = document.createEvent('Event')
        e.initEvent("touchstart", true, true)
      }
    }
    input.dispatchEvent(e)
    setTimeout(() => {
      input.removeEventListener('touchstart', touchstart)
    }, 10)
  }
  ionInput.setFocus()
  input.focus()
}

Object.assign(window, {
  isTouchDevice,
  focus
})

export const hideRecaptcha = () => {
  const recaptcha = document.querySelector('.grecaptcha-badge');
  if (recaptcha) return recaptcha.setAttribute('style', 'display: none');
  else requestAnimationFrame(hideRecaptcha)
}

export const hashCode = (str: string) => {
  var hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

type Map = {
  [key: string]: any
}

export const jsonPointerToPath = (jsonPointer: string) => {
  const validPath = jsonPointer.split('/')
    .map(x => x.trim())
    .filter(x => x !== '#')
  return validPath
}

export const jsonPointerResolve = (object: Map, ref: string, { throwError = false, defaultValue = 'Unknown' } = {}) => {
  const validLodashPath = jsonPointerToPath(ref)
  const element = get(object, validLodashPath, defaultValue)
  if (element === undefined && throwError) throw new Error('Invalid json schema ref path ' + ref)
  return element
}

export const nFormatter = (num: number, digits: number) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export const digitToRank = (n: number) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export const htmlEncode = (rawStr: string) => rawStr.replace(/[\u00A0-\u9999<>&]/gim, function (i) {
  return '&#' + i.charCodeAt(0) + ';';
});

export const disableIosDoubleTapZoom = () => {
  var lastTouchEnd = 0;
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime();
    if (now - lastTouchEnd <= 300) {
      event.preventDefault();
    }
    lastTouchEnd = now;
  }, false);
}

export const createScopeString = (scope: string[]) => {
  let link = scope[0]
  for (let i = 1; i < scope.length; i++) {
    link += '['
    link += scope[i]
    link += ']'
  }
  return link
}

type FuncType = (...args: any[]) => Promise<any>

export const pipeFunc = <T extends FuncType>(callback: T) => {
  let promise: Promise<ReturnType<T>> | void
  return async (...args: Parameters<T>) => {
    if (promise) return promise
    promise = callback(...args)
    const result = await promise
    promise = undefined
    return result
  }
}

export const repeatString = (input: string, count: number) => {
  return new Array(count).fill(input).join('')
}

export const slugify = (text: string) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-')         // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '');            // Trim - from end of text
}

