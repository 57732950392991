import Bowser from 'bowser'
import chromeImage from './chrome.png'
import { isPlatform } from '@ionic/core'

const isHybrid = isPlatform('hybrid')

const parseResult = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = parseResult.satisfies({
    // or in general
    chrome: ">=76",
    firefox: ">=31",
    edge: ">=76",
    safari: ">=9",
    samsung_internet: ">=9"
});

if (!isHybrid && !isValidBrowser) {
    const root = document.createElement('div')
    root.setAttribute('style', 'text-align:center;display:block;position:absolute;left:0;top:0;right:0;bottom:0;z-index:999999;background-color:#ffffff')

    const innerView = document.createElement('div')
    root.appendChild(innerView)
    innerView.setAttribute('style', 'position:absolute;max-width: 90%;top:50%;left:50%;transform:translate(-50%, -50%);width:500px')

    const h1 = document.createElement('h1')
    innerView.appendChild(h1)
    h1.textContent = parseResult.getBrowserName() + ' is not supported'

    const information = document.createElement('p')
    innerView.appendChild(information)
    information.textContent = 'Platform: ' + parseResult.getPlatformType()

    information.textContent += ', operating system: ' + parseResult.getOSName()
    information.textContent += ', version: ' + parseResult.getOSVersion()
    information.textContent += ', browser: ' + parseResult.getBrowserName()
    information.textContent += ', version: ' + parseResult.getBrowserVersion()

    innerView.appendChild(document.createElement('hr')).setAttribute('style', 'border-top: 1px solid #ccc')

    const suggested = document.createElement('p')
    innerView.appendChild(suggested)
    suggested.textContent = 'Please use the latest version of chrome:'

    const downloadChromeLink = document.createElement('a')
    downloadChromeLink.setAttribute('href', 'https://www.google.com/chrome/')
    downloadChromeLink.setAttribute('target', '_blank')

    const image = document.createElement('img')
    image.src = chromeImage
    downloadChromeLink.appendChild(image)
    image.setAttribute('style', 'max-width: 50%')

    downloadChromeLink.appendChild(document.createElement('br'))

    const belowImage = document.createElement('span')
    belowImage.innerText = 'Instructions for installing the latest version of chrome'
    downloadChromeLink.appendChild(belowImage)

    innerView.appendChild(downloadChromeLink)

    const afterThat = document.createElement('p')
    afterThat.appendChild(document.createTextNode('After downloading copy the following link: '))
    const bold = document.createElement('b')
    bold.textContent = window.location.href 
    afterThat.appendChild(bold)
    afterThat.appendChild(document.createTextNode(' into the address bar of the newly installed browser.'))
    innerView.appendChild(afterThat)

    document.body.appendChild(root)
}

