import { useContext } from 'react'
import WorkflowStepButton from '../types/workflow-step-button';
import _get from 'lodash/get'
import { WorkflowStepResponseDocument } from '../types/workflow-step-response-document';
import { IntlContext } from 'react-intl';
import * as ROUTES from '../constants/routes'
import useStepActionHandler from './useStepActionHandler';
import PushStep from '../types/push-step';
import { useHistory } from 'react-router';

type Action = WorkflowStepButton["action"]

const useStepActionTrigger = () => {
  const actionHandler = useStepActionHandler()
  const history = useHistory()
  return (step: WorkflowStepResponseDocument, action: Action, pushStep: PushStep) => {
    const act = actionHandler(step, action)
    const backId = _get(step, 'data.relationships.prev.data.id') || _get(step, 'data.relationships.up.data.id')

    if (!act) return

    if (act.restart) {
      const variation = _get(step, 'data.relationships.workflowVariation.data.id')
      const route = ROUTES.WORKFLOW
        .replace(':variation', variation)
      window.location.href = route
      return
    } else if (act.historyBack){
      history.goBack()
    } else {
      pushStep(act.id, act.options)
    }
  }
}

export default useStepActionTrigger
