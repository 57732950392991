import { InputChangeEventDetail } from '@ionic/core';
import { IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonList, IonRow, IonToast, IonPage, IonContent, IonLabel } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { errorToString, htmlEncode } from '../../lib/helpers';
import Card from '../../components/common/Card/Card';
import styles from './Register.module.css';
import { loadReCaptcha } from 'react-recaptcha-v3'
import useShortcut from '../../hooks/useShortcut';
import { useHistory } from 'react-router';
import * as ROUTES from '../../constants/routes'
import useApi from '../../hooks/useApi';

type Props = {
}

const Register: React.FC<Props> = (props) => {
  const history = useHistory()
  const [email, setEmail] = useState('user@example.com')
  const [name, setName] = useState('John Doe')
  const [password, setPassword] = useState('password')
  const [error, setError] = useState<string | null>(null)
  const api = useApi()
  const handleEmailChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setEmail(e.detail.value!)
  }
  const handlePasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPassword(e.detail.value!)
  }
  const handleNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setName(e.detail.value!)
  }

  const handleErrorClose = () => setError(null)

  const submit = async () => {
    const endpoint = '/api/v1/users/register'
    const method = 'POST'
    const body = {
      email: email.toLowerCase(),
      password,
      name
    }
    try {
      await api({ endpoint, method, body })
      window.alert('Registration successfull, please verify your email and then sign-in using your login credentials.')
      history.replace(ROUTES.LOGIN)
    }
    catch (err) {
      setError(errorToString(err))
    }
  }

  useEffect(() => {
    (loadReCaptcha as any)(process.env.REACT_APP_RECAPTCHA_SITE_KEY, () => {
      console.log('recaptcha loaded', process.env.REACT_APP_RECAPTCHA_SITE_KEY)
    });
  }, [])

  useShortcut('enter', submit)

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage
                id="generic.signup"
                defaultMessage="Sign-up"
                description="Sign-up message" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            
            <IonList>
              <IonItem lines="none" class="ion-no-padding">
                <IonLabel position="floating">
                  <FormattedMessage id="register.name"
                    defaultMessage="Name"
                    description="Name label" />
                </IonLabel>
                <IonInput
                  name="thisFixesAutofillOnChangeEvents"
                  className={styles.input}
                  autocorrect="off"
                  autocomplete="on"
                  type="text"
                  required={true}
                  value={name}
                  onIonChange={handleNameChange} />
              </IonItem>
              <IonItem lines="none" class="ion-no-padding">
                <IonLabel position="floating">
                  <FormattedMessage id="register.email"
                    defaultMessage="Email"
                    description="Email label" />
                </IonLabel>
                <IonInput
                  name="thisFixesAutofillOnChangeEvents"
                  className={styles.input}
                  autocorrect="off"
                  autocomplete="on"
                  type="email"
                  required={true}
                  value={email}
                  onIonChange={handleEmailChange} />
              </IonItem>
              <IonItem lines="none" class="ion-no-padding">
                <IonLabel position="floating">
                  <FormattedMessage id="register.password"
                    defaultMessage="Password"
                    description="Password label" />
                </IonLabel>
                <IonInput
                  name="thisFixesAutofillOnChangeEvents"
                  className={styles.input}
                  autocorrect="off"
                  autocomplete="off"
                  type="password"
                  required={true}
                  value={password}
                  onIonChange={handlePasswordChange} />
              </IonItem>
            </IonList>
            <IonRow className={styles.btns}>
              <IonButton class={styles.btn} disabled={!email.trim() || !password.trim()} onClick={submit}>
                <FormattedMessage
                  id="generic.submit"
                  defaultMessage="Submit"
                  description="Submit message" />
              </IonButton>
            </IonRow>

          </IonCardContent>

          <IonToast
            isOpen={!!error}
            onDidDismiss={handleErrorClose}
            message={htmlEncode(errorToString(error))}
            duration={10000}
            color="danger"
          />
        </Card>
      </IonContent>
    </IonPage>
  )
};

export default Register;
