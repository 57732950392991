import { InputChangeEventDetail } from '@ionic/core';
import { IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSpinner, IonToast } from '@ionic/react';
import clsx from 'clsx';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import _get from 'lodash/get';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, IntlContext } from 'react-intl';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RouteComponentProps } from 'react-router-dom';
import Card from '../../components/common/Card/Card';
import Overlay from '../../components/common/Overlay/Overlay';
import Phone from '../../components/workflow/Phone/Phone';
import { useFocusAndAutofill } from '../../hooks/useFocusAndAutofill';
import { errorToString, htmlEncode } from '../../lib/helpers';
import { WorkflowDocument } from '../../types/workflow-document';
import styles from './WorkflowStart.module.css';
import useWorkflowState from '../../hooks/useWorkflowState';
import { useMountedState } from 'react-use';

type RouteProps = {
  variation: string
}

const verifyRecaptcha = (siteKey: string) => {
  if (siteKey) {
    return new Promise((resolve) => {
      (window as any).grecaptcha.ready(function () {
        resolve((window as any).grecaptcha.execute(siteKey, { action: 'submit' }))
      });
    })
  } else {
    return Promise.resolve(null)
  }
}

const WorkflowStart: React.FC<RouteComponentProps<RouteProps>> = (props) => {
  const workflowVariationId = props.match.params.variation
  const [workflowDocument, setWorkflowDocument] = useState<WorkflowDocument | null>(null)
  const format = _get(workflowDocument, 'data.attributes.phoneVerification.phoneFormat')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [recaptchaVerified, setRecaptchaVerified] = useState(false)
  const [phoneVerified, setPhoneVerified] = useState(false)
  const [showPhoneVerification, setShowPhoneVerification] = useState(false)
  const [showPhoneTokenVerification, setShowPhoneTokenVerification] = useState(false)
  const [phoneNumberRaw, setPhoneNumber] = useState('')
  const [formattedValue, setFormattedValue] = useState(new AsYouType(format).input(phoneNumberRaw))
  const [phoneVerificationCode, setPhoneVerificationCode] = useState('')
  const phoneVerificationCodeRef = useRef<HTMLIonInputElement>() as React.MutableRefObject<HTMLIonInputElement>
  const { redirectToWorkflowResult } = useWorkflowState()
  const isMounted = useMountedState()

  const phoneNumber = parsePhoneNumberFromString(phoneNumberRaw, format)

  const intl = useContext(IntlContext)

  const handleValueChange = (newValue: string) => {
    setPhoneNumber(newValue)
    const formatter = new AsYouType(format)
    setFormattedValue(formatter.input(newValue))
  }

  const createSubmission = async () => {
    if (!workflowDocument) return console.warn('workflow document is undefined')
    setIsLoading(true)
    try {
      const recaptchaToken = workflowDocument.data.attributes.recaptchaSiteKey && await verifyRecaptcha(workflowDocument.data.attributes.recaptchaSiteKey)
      const url = new URL(`/api/v1/workflow-variations/${workflowVariationId}/workflow-result`, process.env.REACT_APP_API_URL || window.location.origin)
      const response = await window.fetch(url.href, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          recaptchaToken
        })
      })
      const result = await response.json()
      if (!isMounted()) return

      if (result.errors) setError(result)
      else {
        const id = _get(result, 'data.id')
        const firstStep = _get(workflowDocument, 'data.attributes.firstStep')
        console.log('redirect')

        redirectToWorkflowResult(workflowVariationId, id, firstStep)
      }
    } catch (err) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const url = new URL(`/api/v1/workflow-variations/${workflowVariationId}`, process.env.REACT_APP_API_URL || window.location.origin)
    setIsLoading(true)
    window.fetch(url.href)
      .then(response => {
        return response.json().then(json => {
          if (!isMounted()) return
          setWorkflowDocument(json)

          if (typeof json.data.attributes.recaptchaSiteKey === 'string') {
            (loadReCaptcha as any)(json.data.attributes.recaptchaSiteKey, () => {
              setRecaptchaVerified(true)
            });
          } else {
            setRecaptchaVerified(true)
          }
        })
      }).catch(err => {
        if (!isMounted()) return
        setError(err)
      }).finally(() => {
        if (!isMounted()) return
        setIsLoading(false)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowVariationId])

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      switch(e.key) {
        case 'Enter': {
          if (showPhoneVerification) {
            if (!phoneNumber || !phoneNumber.isValid()) {
              window.alert(intl.formatMessage({
                id: 'workflow.please-fill-in-valid-phone-number-first',
                description: 'Phone number is empty message',
                defaultMessage: 'Please fill in a valid phone number first.'
              }))
            } else {
              submitPhoneNumber()
            }
          } else if (showPhoneTokenVerification) {
            if (phoneVerificationCode.trim().length === 0) {
              window.alert(intl.formatMessage({
                id: 'workflow.please-fill-in-form-first',
                description: 'Phone verification token is empty message',
                defaultMessage: 'Please fill in a token first.'
              }))
            } else {
              submitPhoneToken()
            }
          }
        }
      }
    }

    window.addEventListener('keydown', keyListener)

    return () => {
      window.removeEventListener('keydown', keyListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPhoneTokenVerification, showPhoneVerification, phoneVerificationCode, phoneNumberRaw])

  useEffect(() => {
    if (workflowDocument && recaptchaVerified) {
      if (workflowDocument.data.attributes.phoneVerification) {
        setShowPhoneVerification(true)
      } else {
        setPhoneVerified(true)
      }
    }
  }, [workflowDocument, recaptchaVerified])

  useEffect(() => {
    if (workflowDocument && phoneVerified) {
      createSubmission()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowDocument, phoneVerified])

  const errorStr = errorToString(error)

  const submitPhoneNumber = async () => {
    setIsLoading(true)
    try {
      const url = new URL(`/api/v1/workflows/${workflowVariationId}/workflow-result/verify-phone`, process.env.REACT_APP_API_URL || window.location.origin)
      const response = await window.fetch(url.href, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: formattedValue,
          channel: 'sms'
        })
      })
      const json = await response.json()
      if (!response.ok) throw json
      if (!isMounted()) return

      setShowPhoneVerification(false)
      setShowPhoneTokenVerification(true)
    } catch (err) {
      if (!isMounted()) return
      setError(errorToString(err))
    } finally {
      if (!isMounted()) return
      setIsLoading(false)
    }
  }

  const submitPhoneToken = async () => {
    setIsLoading(true)
    try {
      const url = new URL(`/api/v1/workflows/${workflowVariationId}/workflow-result/verify-phone-token`, process.env.REACT_APP_API_URL || window.location.origin)
      const response = await window.fetch(url.href, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: formattedValue,
          code: phoneVerificationCode.trim(),
        })
      })
      const json = await response.json()
      if (!response.ok) throw json
      if (!isMounted()) return

      if (json.data.attributes.success !== true) {
        setError("Phone verification was unsuccessful.")
      } else {
        setShowPhoneTokenVerification(false)
        setPhoneVerified(true)
      }
    } catch (err) {
      if (!isMounted()) return
      setError(errorToString(err))
    } finally {
      if (!isMounted()) return
      setIsLoading(false)
    }
  }

  useFocusAndAutofill(phoneVerificationCodeRef, phoneVerificationCode, setPhoneVerificationCode)
  
  const handlePhoneVerificationChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPhoneVerificationCode(e.detail.value!)
  }

  const handleCloseErrorToast = () => setError(null)

  
  return (
    <IonPage className={clsx(styles.page, showPhoneTokenVerification || showPhoneVerification ? styles.show : styles.hide)}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              {_get(workflowDocument, 'data.attributes.name') || <FormattedMessage id="generic.loading-message" description="A generic loading message" defaultMessage="Loading..." />}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <Overlay active={isLoading} className={styles.overlay} overlay={<IonSpinner name="crescent" style={{ margin: 'auto' }} />}>
              {showPhoneVerification ? <>
                <FormattedMessage
                  id="workflows.validate-phone-number-message"
                  defaultMessage={"Please validate your phone number by entering it below in {format} format and pressing \"Submit\"."}
                  values={{ format: format || 'international' }}
                  description="Phone number validation message" />
                <div className={styles.phoneContainer}>
                  <output className={clsx(styles.phoneOutput, phoneNumber && phoneNumber.isValid() ? styles.valid : styles.invalid)}>{' ' + formattedValue}</output>
                  <div className={clsx(styles.phoneWrapper)}>
                    <Phone value={phoneNumberRaw} onChange={handleValueChange} />
                  </div>
                </div>
                <IonRow className={styles.btns}>
                  <IonButton class={styles.btn} disabled={!phoneNumber || !phoneNumber.isValid()} onClick={submitPhoneNumber}>
                    <FormattedMessage
                      id="generic.submit"
                      defaultMessage="Submit"
                      description="Submit message" />
                  </IonButton>
                </IonRow>
              </> : null}
              {showPhoneTokenVerification ? <>
                <FormattedMessage
                  id="workflows.validate-phone-number-token-message"
                  defaultMessage={"Please enter the code that we have sent to you via sms."}
                  description="Phone number token validation message" />

                <IonList>
                  <IonItem class="ion-no-padding">
                    <IonLabel position="floating">
                      <FormattedMessage id="workflow.code"
                        defaultMessage="Code"
                        description="Code label" />
                    </IonLabel>
                    <IonInput
                      ref={phoneVerificationCodeRef}
                      autocomplete="one-time-code"
                      name="thisFixesAutofillOnChangeEvents"
                      autofocus={true}
                      required={true}
                      style={{ textAlign: 'center' }}
                      value={phoneVerificationCode}
                      onIonChange={handlePhoneVerificationChange} />
                  </IonItem>
                </IonList>

                <IonRow className={styles.btns}>
                  <IonButton className={styles.btn} disabled={!phoneVerificationCode || phoneVerificationCode.trim().length === 0} onClick={submitPhoneToken}>
                    <FormattedMessage
                      id="generic.submit"
                      defaultMessage="Submit"
                      description="Submit message" />
                  </IonButton>
                </IonRow>
              </> : null}
            </Overlay>
          </IonCardContent>
        </Card>
        <IonToast
          isOpen={!!error}
          onDidDismiss={handleCloseErrorToast}
          message={htmlEncode(errorStr)}
          color="danger"
          duration={10000}
        />
      </IonContent>
    </IonPage>
  )
};

export default WorkflowStart;
