import React, { useContext, useEffect, useRef, useState } from 'react'
import { IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonToast, IonLoading } from '@ionic/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, useHistory } from 'react-router';
import { AuthContext } from '../../contexts/auth';
import { errorToString, htmlEncode } from '../../lib/helpers';
import Card from '../../components/common/Card/Card';
import styles from './ForgotPassword.module.css'
import qs from 'qs'
import _get from 'lodash/get'
import useApi from '../../hooks/useApi';
import * as ROUTES from '../../constants/routes'

interface Props {

}

const ForgotPassword: React.FC<Props & RouteComponentProps> = props => {
  const auth = useContext(AuthContext)
  const [error, setError] = useState<string | null>(null)
  const handleErrorClose = () => setError(null)
  const email = useRef<HTMLIonInputElement | null>(null)
  const form = useRef<HTMLFormElement | null>(null)
  const api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    forgotPassword()
  }

  const history = useHistory()

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (query && email.current) {
      email.current.value = query.email ?.toString()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const forgotPassword = async () => {
    const endpoint = `/api/v1/password/forgot`
    const method = 'POST'
    const body = {
      email: email.current ?.value
    }
    try {
      setIsLoading(true)
      const response = await api({ endpoint, method, body })
      const queryString = qs.stringify({ email: email.current?.value })
      console.log('forgot password response', response)
      history.replace(ROUTES.FORGOT_PASSWORD_DONE + '?' + queryString)
    } catch (err) {
      const status = '' + _get(err, 'errors.0.status')
      switch (status) {
        case '404':
          setError('Did you spell the email address correctly? This email address is unknown to us.')
          break;

        default:
          setError(errorToString(err))
          break
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage
                id="login.reset-password-page-title"
                defaultMessage="Reset Password"
                description="Reset password message" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={styles.ionCardContent}>
            <form ref={form} action="/" className={styles.flex} onSubmit={handleSubmit}>
              <div />
              <IonList>
                <IonItem lines="none" class="ion-no-padding">
                  <IonLabel position="stacked">
                    <FormattedMessage id="login.email"
                      defaultMessage="Email"
                      description="Email label" />
                  </IonLabel>
                  <IonInput
                    name="thisFixesAutofillOnChangeEvents"
                    className={styles.input}
                    autocorrect="off"
                    autocomplete="on"
                    type="email"
                    required={true}
                    ref={el => email.current = el} />
                </IonItem>
              </IonList>
              <IonRow className={styles.btns}>
                <IonButton type="submit" class={styles.btn}>
                  <FormattedMessage
                    id="generic.reset"
                    defaultMessage="Reset"
                    description="Reset password button" />
                </IonButton>
              </IonRow>
            </form>

          </IonCardContent>

          <IonLoading message={intl.formatMessage({ id: 'generic.loading-message' })} isOpen={isLoading} />

          <IonToast
            isOpen={!!error}
            onDidDismiss={handleErrorClose}
            message={htmlEncode(errorToString(error))}
            duration={10000}
            color="danger"
          />

          <IonToast
            isOpen={auth.oauthLoading}
            message="Loading..."
            duration={10000}
            color="light"
          />
        </Card>
      </IonContent>
    </IonPage>
  )
}

export default ForgotPassword
