
import { useContext } from "react";
import { APIContext } from "../contexts/api";

// Hook
function useApiHost() {
  return useContext(APIContext).apiHost
}

export default useApiHost
