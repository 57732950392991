import { AnimationBuilder, createAnimation, Animation } from '@ionic/core';
import { IonRouterOutlet } from '@ionic/react';
import _get from 'lodash/get';
import React, { useEffect, lazy, useContext } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import RenderWorkflowCSS from '../../workflow/WorkflowCustomCSS/WorkflowCustomCSS'
import NotFound from '../../../pages/NotFound/NotFound'

/* Routes */

import * as ROUTES from '../../../constants/routes';
import FirstWorkflowStep from '../../../pages/FirstWorkflowStep/FirstWorkflowStep';
import WorkflowStep from '../../../pages/WorkflowStep/WorkflowStep';
import Login from '../../../pages/Login/Login';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import Register from '../../../pages/Register/Register';
import HardReset from '../../../pages/HardReset/HardReset';
import { LoggerContext } from '../../../contexts/logger';
import ForgotPassword from '../../../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../../../pages/ResetPassword/ResetPassword'
import ForgotPasswordDone from '../../../pages/ForgotPasswordDone/ForgotPasswordDone';
import WorkflowStart from '../../../pages/WorkflowStart/WorkflowStart';
import { compose } from 'recompose';
import { withWorkflowState } from '../../../hocs/withWorkflowState';
import useWorkflowState from '../../../hooks/useWorkflowState';
import * as UI_CONSTANTS from '../../../constants/ui'

/* Async Routes */

const AdminOverview = lazy(() => import('../../../pages/AdminOverview/AdminOverview'))

/* End Routes */

let isForward = true

let enteringAnimation: Animation | null = null
let leavingAnimation: Animation | null = null

const animation: AnimationBuilder = (baseEl, opts) => {

  const duration = 500

  enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .duration(duration)
    .easing('ease')
    .beforeRemoveClass('ion-page-invisible')
    .fromTo('transform', isForward ? 'translateY(50%)' : 'translateY(-50%)', 'translateY(0px)')
  // 90% instead of 100% causes the card to have the correct height upon animating on iOS

  leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .duration(duration)
    .easing('ease')
    .fromTo('transform', isForward ? 'translateY(-50%)' : 'translateY(50%)', isForward ? 'translateY(-100%)' : 'translateY(100%)')

  return createAnimation()
    .addAnimation([enteringAnimation, leavingAnimation])
}

type HistoryItem = {
  key: string,
  invert: boolean
}

let historyStack: HistoryItem[] = []

const Routes = () => {
  const workflowState = useWorkflowState()
  const history = useHistory()
  const location = useLocation()
  const { logger } = useContext(LoggerContext)

  useEffect(() => {
    historyStack = [{ key: location.key as string, invert: false }]
    const unregister = history.listen(location => {

      logger.logInformation('React Router', 'History change', { location, action: history.action })
      switch (history.action) {
        case 'PUSH':
          const invert = _get(location, 'state.back', false)
          historyStack = [{ invert, key: location.key as string }]
          isForward = !invert
          break;

        case 'POP':
          if (historyStack[1] && historyStack[1].key === location.key) {
            historyStack.splice(0, 1)
            isForward = !_get(historyStack, '0.invert', false)
          } else {
            isForward = _get(historyStack, '0.invert', false)
            historyStack.splice(0, 0, { key: location.key as string, invert: false })
          }
          break;

        case 'REPLACE':
          isForward = true
          historyStack.splice(0, 1, { key: location.key as string, invert: false })
          break;
      }
    })

    return () => {
      unregister()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Switch>
        <ProtectedRoute path={ROUTES.ADMIN_OVERVIEW_CATCHALL} component={AdminOverview} childProps={{}} />
        <Route path={ROUTES.LOGIN} component={Login} exact={true} />
        <Route path={ROUTES.REGISTER} component={Register} exact={true} />
        <Route path={ROUTES.WORKFLOW_STEP} component={workflowState.workflowResultId ? WorkflowStep : WorkflowStart} exact={true} />
        <Route path={ROUTES.WORKFLOW} component={WorkflowStart} exact={true} />
        <Route path={ROUTES.HARD_RESET} component={HardReset} exact={true} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} exact={true} />
        <Route path={ROUTES.FORGOT_PASSWORD_DONE} component={ForgotPasswordDone} exact={true} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} exact={true} />
        {/* <Route path={ROUTES.HOME} component={Home} exact={true} /> */}
        {/* <Redirect exact={true} from={ROUTES.INDEX} to={ROUTES.HOME} /> */}
        <Redirect from={ROUTES.ADMIN_OVERVIEW} exact={true} to={ROUTES.ADMIN_OVERVIEW_WORKFLOWS} />
        <Redirect
          from={process.env.REACT_APP_DEFAULT_WORKFLOW ? '/' : '/never-match'}
          exact={true}
          to={ROUTES.WORKFLOW.replace(':variation', process.env.REACT_APP_DEFAULT_WORKFLOW || '') || '/'} />
        <Route path="/" component={NotFound} />
      </Switch>
      <Route path={ROUTES.WORKFLOW} component={RenderWorkflowCSS} />
    </>
  )
}


const enhancer = compose(
  withWorkflowState
)

export default enhancer(Routes)
