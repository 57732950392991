import React, { useEffect, useReducer, useRef } from 'react';
import { Action, AuthContext, AuthDefaultContext, defaultAuthContext, OAuth } from '../contexts/auth';
import { api } from '../contexts/api';
import { apiHostPromise } from './withApi';

const getPersistedOAuthToken = () => {
  let rawLocal, rawSession
  try {
    rawLocal = localStorage.getItem('oauthToken')
    rawSession = sessionStorage.getItem('oauthToken')
  } catch (err) {
    alert('Unable to read local/session storage data. Please check if you have cookies or local/session storage disabled in your browser.')
  }
  const rememberMe = !!rawLocal
  const raw = rawLocal || rawSession
  const token = raw && JSON.parse(raw) as AuthDefaultContext["oauth"] | false
  return { token, rememberMe }
}

const reducer = (state: AuthDefaultContext, action: Action): AuthDefaultContext => {
  switch (action.type) {
    case 'logout':
      localStorage.removeItem('oauthToken')
      return { ...state, oauth: null, isAuthenticated: false, oauthError: null, oauthLoading: false };
    case 'fetchAuthTokenFailed':
      return { ...state, oauth: null, isAuthenticated: false, oauthError: action.error, oauthLoading: false };
    case 'fetchAuthTokenLoading':
      return { ...state, oauth: null, isAuthenticated: false, oauthLoading: true, oauthError: null };
    case 'fetchAuthTokenSuccess':
      if (action.rememberMe) {
        localStorage.setItem("oauthToken", JSON.stringify(action.oauth))
        sessionStorage.removeItem('oauthToken')
      } else {
        localStorage.removeItem('oauthToken')
        sessionStorage.setItem("oauthToken", JSON.stringify(action.oauth))
      }
      return { ...state, oauth: action.oauth, isAuthenticated: true, oauthLoading: false, oauthError: null };
    default:
      throw new Error();
  }
}

export function withAuth<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithAuth = (props: P) => {
    const [value, dispatch] = useReducer(reducer, defaultAuthContext)
    const promise = useRef<Promise<OAuth | null> | null>(null)

    const refreshToken = async () => {
      const { token, rememberMe } = getPersistedOAuthToken()
      if (!token) return null
      const expiresUnixTimestamp = token.created_at + token.expires_in
      const nowUnixTimestamp = Date.now() / 1000
      const oneHour = 60 * 60
      if (nowUnixTimestamp < expiresUnixTimestamp - oneHour) {
        if (!value.oauth) dispatch({ type: 'fetchAuthTokenSuccess', oauth: token, rememberMe })
        return token
      } else {
        const body = {
          grant_type: 'refresh_token',
          refresh_token: token.refresh_token,
          scopes: 'read write',
          client_id: value.clientId
        }
        const host = await apiHostPromise
        const response = await api({ endpoint: 'oauth/token', method: 'POST', body, accessToken: false }, null, host)
        dispatch({ type: 'fetchAuthTokenSuccess', oauth: response, rememberMe })
        return response
      }
    }
    const refresh = () => {
      if (promise.current) return promise.current
      promise.current = refreshToken()
      promise.current.then(() => {
        promise.current = null
      })
      return promise.current
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { refresh() }, [])

    return <AuthContext.Provider value={{ ...value, dispatch, refresh, initialized: true }}>
      <WrappedComponent  {...props} />
    </AuthContext.Provider>
  };
  return ComponentWithAuth;
}

