import { IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonToast } from '@ionic/react';
import querystring from 'querystring';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { Redirect, useLocation } from 'react-router';
import { AuthContext, AuthDefaultContext } from '../../contexts/auth';
import useShortcut from '../../hooks/useShortcut';
import { errorToString, htmlEncode } from '../../lib/helpers';
import Card from '../../components/common/Card/Card';
import styles from './Login.module.css';
import * as ROUTES from '../../constants/routes'
import RawLink from '../../components/router/RawLink/RawLink';
import { InputChangeEventDetail } from '@ionic/core'

type Props = {

}

const Login: React.FC<Props> = (props) => {
  const auth = useContext(AuthContext)
  const [error, setError] = useState<string | null>(null)
  const handleErrorClose = () => setError(null)
  const email = useRef<HTMLIonInputElement | null>(null)
  const password = useRef<HTMLIonInputElement | null>(null)
  const form = useRef<HTMLFormElement | null>(null)
  const [emailValue, setEmailValue] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  useEffect(() => {
    setEmailValue(email.current?.value?.toString() || '')
  }, [])

  const emailValueChange = (event: CustomEvent<InputChangeEventDetail>) => {
    setEmailValue(event.detail.value?.toString() || '')
  }

  const requestOauthToken = async () => {
    const url = new URL('/oauth/token', process.env.REACT_APP_API_URL || window.location.origin)
    const response = await window.fetch(url.href, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        grant_type: 'password',
        email: email.current!.value!.toString().toLowerCase(),
        password: password.current!.value,
        client_id: auth.clientId,
        scope: 'read write'
      })
    })

    const json = await response.json() as Promise<AuthDefaultContext["oauth"]>
    if (!response.ok) throw json
    return json
  }

  const doLogin = async () => {
    try {
      auth.dispatch({ type: 'fetchAuthTokenLoading' })
      const result = await requestOauthToken()
      auth.dispatch({ type: 'fetchAuthTokenSuccess', oauth: result, rememberMe })
    } catch (err) {
      setError(err)
      auth.dispatch({ type: 'fetchAuthTokenFailed', error: err })
    }
  }

  useShortcut('enter', () => {
    const valid = form.current?.checkValidity()
    if (valid) {
      doLogin()
    } else {
      form.current?.reportValidity()
    }
  })

  useEffect(() => {
    (loadReCaptcha as any)(process.env.REACT_APP_RECAPTCHA_SITE_KEY, () => {
      console.log('recaptcha loaded', process.env.REACT_APP_RECAPTCHA_SITE_KEY)
    });
  }, [])

  const location = useLocation()
  const { redirect: qsRedirect } = querystring.parse(location.search.replace('?', ''))
  if (auth.oauth) return <Redirect to={'' + (qsRedirect || ROUTES.ADMIN_OVERVIEW)} />

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doLogin()
  }

  return (
    <IonPage className={styles.page}>
      <IonContent className={styles.ionContent}>
        <Card>
          <IonCardHeader>
            <IonCardTitle>
              <FormattedMessage
                id="generic.login"
                defaultMessage="Sign-in"
                description="Sign-in message" />
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={styles.ionCardContent}>
            <form ref={form} action="/" className={styles.flex} onSubmit={handleSubmit}>
              <div />
              <IonList>
                <IonItem class="ion-no-padding" lines="none">
                  <IonLabel position="stacked">
                    <FormattedMessage id="login.email"
                      defaultMessage="Email"
                      description="Email label" />
                  </IonLabel>
                  <IonInput
                    name="thisFixesAutofillOnChangeEvents"
                    className={styles.input}
                    autocorrect="off"
                    autocomplete="on"
                    onIonChange={emailValueChange}
                    type="email"
                    required={true}
                    ref={el => email.current = el} />
                </IonItem>
                <IonItem class="ion-no-padding" lines="none">
                  <IonLabel position="stacked">
                    <FormattedMessage id="login.password"
                      defaultMessage="Password"
                      description="Password label" />
                  </IonLabel>
                  <IonInput
                    name="thisFixesAutofillOnChangeEvents"
                    className={styles.input}
                    autocorrect="off"
                    autocomplete="off"
                    type="password"
                    required={true}
                    ref={el => password.current = el} />
                </IonItem>
                <IonItem lines="none" class="ion-no-padding ion-padding-top">
                  <IonCheckbox slot="start" checked={rememberMe} onIonChange={e => setRememberMe(e.detail.checked)} />
                  <IonLabel>Remember me</IonLabel>
                </IonItem>
              </IonList>
              <IonRow className={styles.btns}>
                <RawLink to={ROUTES.FORGOT_PASSWORD + '?email=' + emailValue}>
                  <IonButton fill="clear" class={styles.btn}>
                    <FormattedMessage
                      id="generic.forgot-password"
                      defaultMessage="Forgot Password"
                      description="Forgot Password" />
                  </IonButton>
                </RawLink>
                <IonButton type="submit" class={styles.btn}>
                  <FormattedMessage
                    id="generic.login"
                    defaultMessage="Login"
                    description="Login message" />
                </IonButton>
              </IonRow>
            </form>

          </IonCardContent>

          <IonToast
            isOpen={!!error}
            onDidDismiss={handleErrorClose}
            message={htmlEncode(errorToString(error))}
            duration={10000}
            color="danger"
          />

          <IonToast
            isOpen={auth.oauthLoading}
            message="Loading..."
            duration={10000}
            color="light"
          />
        </Card>
      </IonContent>
    </IonPage>
  )
};

export default Login;
