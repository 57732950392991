import { createContext } from "react";
import { OAuth } from "./auth";

type FetchParams = {
  endpoint: string,
  accessToken?: boolean,
  body?: any
  method?: 'PATCH' | 'POST' | 'GET' | 'PUT' | 'DELETE',
  timeout?: number,
  returnType?: 'json' | 'blob' | 'response' | 'text'
}

export const api = async (params: FetchParams, authToken?: OAuth | null | false, apiHost?: string | null) => {
  if (!authToken && params.accessToken) throw new Error('Could not request a protected resource without being logged in.')
  const { endpoint, accessToken, body: bodyObj, method = 'GET' } = params
  console.log('api', apiHost)
  const url = new URL(endpoint,  apiHost || undefined)
  const headers: HeadersInit = new Headers();
  headers.set('Accept', 'application/json');
  if (bodyObj) headers.set('Content-Type', 'application/json');
  if (accessToken && authToken) headers.set('Authorization', `Bearer ${authToken.access_token}`)
  const requestInit: RequestInit = { headers, method }
  if (bodyObj) requestInit.body = JSON.stringify(bodyObj)
  const response = await window.fetch(url.href, requestInit)
  switch (params.returnType) {
    case 'text':
      if (!response.ok) throw new Error(response.statusText)
      const text = await response.text()
      return text
    case 'response':
      if (!response.ok) throw new Error(response.statusText)
      return response
    case 'blob':
      if (!response.ok) throw new Error(response.statusText)
      const blob = await response.blob()
      return blob
    default:
      const json = await response.json()
      if (!response.ok) throw json
      return json
  }
}

interface IAPIContext {
  api: typeof api,
  apiHost: string | null
}

const apiContext = {
  api: () => {
    throw new Error('Please wrap this component with "withApi"')
  },
  apiHost: null
}

export const APIContext = createContext<IAPIContext>(apiContext);
