import { IonContent, IonPage, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import React from 'react';
import styles from './NotFound.module.css';
import { FormattedMessage } from 'react-intl';
import Card from '../../components/common/Card/Card'

const NotFound: React.FC = () => {
  return (
    <>
      <IonPage className={styles.page}>
        <IonContent className={styles.ionContent}>
          <Card>
            <IonCardHeader>
              <IonCardSubtitle>
                404
            </IonCardSubtitle>
              <IonCardTitle>
                <FormattedMessage id="not-found.not-found-title"
                  defaultMessage="Not found"
                  description="Not found title" />
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <FormattedMessage id="not-found.not-found-content"
                defaultMessage="We could not find this page..."
                description="A message which indicates to the user that this page could not be found" />
            </IonCardContent>
          </Card>
        </IonContent>
      </IonPage>
    </>
  )
};

export default NotFound;
