import React, { useEffect, useState, useContext } from 'react';
import { ActionCableContext } from '../contexts/action-cable';
import { AuthContext } from '../contexts/auth'
import actionCable, { Cable } from 'actioncable';

export function withActionCable<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithActionCable = (props: P) => {
    const authToken = useContext(AuthContext)
    const [cableApp, setCableApp] = useState<null | Cable>(null)

    useEffect(() => {
      if (authToken.oauth) {
        const cableHost = new URL('/', process.env.REACT_APP_API_URL || window.location.origin).host
        const protocol = process.env.NODE_ENV === 'production' ? 'wss' : 'ws'
        setCableApp(
          actionCable.createConsumer(`${protocol}://${cableHost}/cable?access_token=${authToken.oauth.access_token}`)
        )
      } else {
        setCableApp(null)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken.oauth?.access_token])

    return <ActionCableContext.Provider value={{ cableApp }}>
      <WrappedComponent  {...props} />
    </ActionCableContext.Provider>
  };
  return ComponentWithActionCable;
}

