export const INPUT_DEBOUNCE = 300
export const DISABLE_DARK_MODE = true
export const ANIMATED_PAGE_NAVIGATION = false
export const REQUIRE_GUARDIAN_TO_RIDE = false

export const WAITING_PARTIES_SORT_BY = '+completedAt'
export const BOARDED_PARTIES_SORT_BY = '-boardedAt'
export const CANCELLED_PARTIES_SORT_BY = '-cancelledAt'

export const SHOW_BOARD_ALL_BUTTON = false
