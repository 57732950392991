import { createContext } from "react";

export interface IWorkflowState {
  workflowResultId: string | null

  setWorkflowResultId: (workflowResultId: string | null) => void
  redirectToWorkflowResult: (workflowVariationId: string, workflowResultId: string | null, stepId: string) => void
  removeWorkflowResultFromSessionStorage: () => void
}

export const defaultState: IWorkflowState = {
  workflowResultId: null,

  setWorkflowResultId: workflowResultId => null,
  redirectToWorkflowResult: (workflowVariationId, workflowResultId) => null,
  removeWorkflowResultFromSessionStorage: () => null
}

export const WorkflowState = createContext(defaultState)
