import React from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps } from 'react-router';

interface RouteProps {
  variation: string
}

const WorkflowCustomCSS: React.FC<RouteComponentProps<RouteProps>> = props => {
  const { match: { params: { variation } } } = props

  const url = new URL(`/api/v1/workflow-variations/${variation}/stylesheet.css`, process.env.REACT_APP_API_URL || window.location.origin)

  return (
    <Helmet>
      <link rel="stylesheet" type="text/css" href={url.href} />
    </Helmet>
  )
}

export default WorkflowCustomCSS
