import { useEffect } from "react";
import { bustCache } from "../../lib/cache-buster";
import { useHistory } from "react-router";

const HardReset = () => {
  const history = useHistory()

  useEffect(() => {
    bustCache()
    history.replace('/')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default HardReset
