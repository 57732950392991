export const WORKFLOW_STEP = '/flow/:variation/:step'
export const WORKFLOW = '/flow/:variation'
export const ADMIN_OVERVIEW = '/admin'
export const ADMIN_OVERVIEW_USERS = '/admin/users'
export const ADMIN_OVERVIEW_USERS_MY_PROFILE = '/admin/users/my-profile'
export const ADMIN_OVERVIEW_USERS_MY_INVITATIONS = '/admin/users/my-invitations'
export const ADMIN_OVERVIEW_USERS_LIST_SPECIFIC = '/admin/users/list/:user'
export const ADMIN_OVERVIEW_WORKFLOWS = '/admin/workflows'
export const ADMIN_OVERVIEW_WORKFLOW_SPECIFIC = '/admin/workflows/:id'
export const ADMIN_OVERVIEW_WORKFLOW_MIXIN_YAML = '/admin/workflows/:id/yaml-mixins/:mixin'
export const ADMIN_OVERVIEW_WORKFLOW_MIXIN_SCSS = '/admin/workflows/:id/scss-mixins/:mixin'
export const ADMIN_OVERVIEW_WORKFLOW_VARIATION_YAML = '/admin/workflows/:id/yaml/:variation'
export const ADMIN_OVERVIEW_WORKFLOW_VARIATION_SCSS = '/admin/workflows/:id/scss/:variation'
export const ADMIN_OVERVIEW_WORKFLOW_YAML = '/admin/workflows/:id/yaml'
export const ADMIN_OVERVIEW_WORKFLOW_SCSS = '/admin/workflows/:id/scss'
export const ADMIN_OVERVIEW_WORKFLOW_SUBMISSIONS = '/admin/workflows/:id/submissions-old-ui'
export const ADMIN_OVERVIEW_WORKFLOW_TICKETING_UI = '/admin/workflows/:id/submissions'
export const ADMIN_OVERVIEW_WORKFLOW_USERS = '/admin/workflows/:id/users'
export const ADMIN_OVERVIEW_WORKFLOW_USERS_SPECIFIC = '/admin/workflows/:id/users/:userRole'
export const ADMIN_OVERVIEW_WORKFLOW_ROLES = '/admin/workflows/:id/roles'
export const ADMIN_OVERVIEW_WORKFLOW_SETTINGS = '/admin/workflows/:id/settings'
export const ADMIN_OVERVIEW_WORKFLOW_GUESTS = '/admin/workflows/:id/guests'
export const ADMIN_OVERVIEW_WORKFLOW_PARTIES = '/admin/workflows/:id/parties'
export const ADMIN_OVERVIEW_WORKFLOW_PARTY_SPECIFIC = '/admin/workflows/:id/parties/:partyid'
export const ADMIN_OVERVIEW_WORKFLOW_GUESTS_SPECIFIC = '/admin/workflows/:id/guests/:guestid'
export const ADMIN_OVERVIEW_WORKFLOW_ANALYTICS = '/admin/workflows/:id/analytics'
export const ADMIN_OVERVIEW_WORKFLOW_ANALYTICS_LOGS = '/admin/workflows/:id/analytics/logs/:variation'
export const ADMIN_OVERVIEW_WORKFLOW_ANALYTICS_VISITORS = '/admin/workflows/:id/analytics/visits/:variation'
export const ADMIN_OVERVIEW_WORKFLOW_ANALYTICS_VISITORS_SPECIFIC = '/admin/workflows/:id/analytics/visits/:variation/:visit'
export const ADMIN_OVERVIEW_WORKFLOW_ANALYTICS_STATISTICS = '/admin/workflows/:id/analytics/statistics'
export const ADMIN_OVERVIEW_WORKFLOW_USER = '/admin/workflows/:id/users/:user'
export const ADMIN_OVERVIEW_CATCHALL = '/admin/*'
export const HARD_RESET = '/reset'
export const HOME = '/home'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const FORGOT_PASSWORD_DONE = '/forgot-password-done'
export const RESET_PASSWORD = '/reset-password'
export const LOGIN = '/login'
export const INDEX = '/'
