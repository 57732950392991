import { IonContent, IonText, IonButton } from '@ionic/react';
import React, { ErrorInfo } from 'react';
import { FormattedMessage } from 'react-intl';
import { errorToString } from '../../../lib/helpers';
import { compose } from 'recompose';
import { withRouter, RouterProps } from 'react-router';
import { LoggerContext } from '../../../contexts/logger';

interface Props { }

interface State {
  error: any
}

class ErrorBoundary extends React.Component<Props & RouterProps, State> {

  static contextType = LoggerContext

  constructor(props: Props & RouterProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    console.log(this.context)
    this.context.logger.captureError(error, { errorInfo })
    this.setState({ error })
  }

  goBack = () => {
    this.props.history.goBack()
    this.setState({ error: null })
  }

  refresh = () => {
    window.location.reload(true)
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <IonContent className="ion-padding">
        <h1><FormattedMessage
          id="error-boundary.woops"
          defaultMessage="Oops..."
          description="Error title"
        /></h1>
        <br />
        <p>
          <FormattedMessage
            id="error-boundary.something-went-wrong"
            defaultMessage="Something went wrong."
            description="Error message"
          />
        </p>

        <IonText color="danger">
          <p>{errorToString(this.state.error)}</p>
        </IonText>

        <IonButton onClick={this.goBack}>
          <FormattedMessage
            id="error-boundary.back"
            defaultMessage="Back"
            description="Back message"
          />
        </IonButton>

        <IonButton onClick={this.refresh}>
          <FormattedMessage
            id="error-boundary.refresh"
            defaultMessage="Refresh"
            description="Refresh message"
          />
        </IonButton>
      </IonContent>;
    }

    return this.props.children;
  }
}

const enhancer = compose<Props & RouterProps, {}>(
  withRouter
)

export default enhancer(ErrorBoundary)
