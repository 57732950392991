import React, { useContext } from "react"
import { IntlContext } from "react-intl"
import _get from 'lodash/get'
import clsx from "clsx"

interface Image {
  src?: string
  name?: string
}

interface Props {
  image?: Image
}

const StepHeaderImage = (props: Props) => {
  const intl = useContext(IntlContext)
  const src = _get(props, 'image.src')
  const name = _get(props, 'image.name')

  if (!src) return null

  return (
    <div>
      <img 
        className={clsx('card-main-image', name && 'card-main-image-' + name)}
        alt={intl.formatMessage({ id: 'step-image.alt' })}
        src={src} />
    </div>
  )
}

export default StepHeaderImage
