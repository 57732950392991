import { useContext } from 'react'
import WorkflowStepButton from '../types/workflow-step-button';
import _get from 'lodash/get'
import { WorkflowStepResponseDocument } from '../types/workflow-step-response-document';
import { IntlContext } from 'react-intl';
import * as ROUTES from '../constants/routes'

type Action = WorkflowStepButton["action"]

const useStepActionHandler = () => {
  const intl = useContext(IntlContext)
  return (step: WorkflowStepResponseDocument, action: Action) => {
    const onError = () => {
      window.alert(intl.formatMessage({
        id: 'application-error.invalid-step',
        description: 'Invalid application step message',
        defaultMessage: `Application error: 
This step has not been configured properly, there is no valid action attached to this button. 
Please contact technical support.`
      }))
      console.error('application-error.invalid-step (pushStep)')
    }

    if (!action) {
      return onError()
    }

    const focus = !!action && !!action.focus
    const replace = !!action && !!action.replace
    const params = action.params
    const options = { focus, back: false, params }

    switch (_get(action, 'type')) {
      case 'next':
        const nextId = _get(step, 'data.relationships.next.data.id') || _get(step, 'data.relationships.up.data.id')

        return {
          id: nextId,
          options
        }

      case 'goto':
        const backId = _get(step, 'data.relationships.prev.data.id') || _get(step, 'data.relationships.up.data.id')
        const gotoId = action.id as string
        if (gotoId === backId) options.back = true

        return {
          id: gotoId,
          options
        }
      case 'up': {
        const upId = _get(step, 'data.relationships.up.data.id')
        return {
          id: upId,
          options
        }
      }
      case 'back':
      case 'prev': {
        options.back = true
        const backId = _get(step, 'data.relationships.prev.data.id') || _get(step, 'data.relationships.up.data.id')
        return {
          id: backId,
          options
        }
      }

      case 'historyBack': {
        return {
          historyBack: true
        }
      }

      case 'restart': {
        const variation = _get(step, 'data.relationships.workflowVariation.data.id')
        const route = ROUTES.WORKFLOW
          .replace(':variation', variation)

        return {
          id: backId,
          options,
          restart: true
        }
      }

      case '__SPECIAL__': {
        break;
      }

      default:
        onError()

        break;

    }
  }
}

export default useStepActionHandler
