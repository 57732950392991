import React, { useState } from 'react';
import { WorkflowState, IWorkflowState, defaultState } from '../contexts/workflow-state'
import { useHistory } from 'react-router';
import * as ROUTES from '../constants/routes'

export function withWorkflowState<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithWorkflowState = (props: P) => {
    let oldWorkflowResultId
    try {
      oldWorkflowResultId = sessionStorage.getItem('workflowResultId')
    } catch (err) {
      alert('Unable to read local/session storage data. Please check if you have cookies or local/session storage disabled in your browser.')
    }
    const [workflowResultId, _setWorkflowResultId] = useState(oldWorkflowResultId || defaultState.workflowResultId)
    const history = useHistory()

    const redirectToWorkflowResult = (workflowVariationId: string, workflowResultId: string | null, stepId: string) => {
      const route = ROUTES.WORKFLOW_STEP.replace(':variation', workflowVariationId).replace(':step', stepId)
      // set to null to prevent the app from loading the previous workflow result
      setWorkflowResultId(workflowResultId)
      history.replace(route)
    }

    const setWorkflowResultId = (workflowResultId: string | null) => {
      if (workflowResultId) sessionStorage.setItem('workflowResultId', workflowResultId)
      else sessionStorage.removeItem('workflowResultId')
      _setWorkflowResultId(workflowResultId)
    }

    const removeWorkflowResultFromSessionStorage = () => {
      sessionStorage.removeItem('workflowResultId')
    }

    const state: IWorkflowState = {
      workflowResultId,
      setWorkflowResultId,
      redirectToWorkflowResult,
      removeWorkflowResultFromSessionStorage
    }

    return <WorkflowState.Provider value={state}>
      <WrappedComponent  {...props} />
    </WorkflowState.Provider>
  };
  return ComponentWithWorkflowState;
}

