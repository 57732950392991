import React, { Suspense } from 'react';
import SuspenseFallback from '../components/router/SuspenseFallback/SuspenseFallback';

export function withSuspense<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithSuspense = (props: P) => {
    return <Suspense fallback={<SuspenseFallback />}>
      <WrappedComponent  {...props} />
    </Suspense>
  };
  return ComponentWithSuspense;
}

