import _get from 'lodash/get';
import React, { lazy } from 'react';
import PushStep from '../../../types/push-step';
import WorkFlowStepContentOptions from '../../../types/workflow-step-content-options';
import { WorkflowStepResponseDocument } from '../../../types/workflow-step-response-document';
import { Redirect } from 'react-router';
import * as ROUTES from '../../../constants/routes'
import OnFinish from '../../../types/finish-step';

const ButtonsStep = lazy(() => import('../../steps/ButtonsStep/ButtonsStep'))
const DateTimeStep = lazy(() => import('../../steps/DateTimeStep/DateTimeStep'))
const EmailStep = lazy(() => import('../../steps/EmailStep/EmailStep'))
const FullNameStep = lazy(() => import('../../steps/FullNameStep/FullNameStep'))
const LoopStep = lazy(() => import('../../steps/LoopStep/LoopStep'))
const PhoneStep = lazy(() => import('../../steps/PhoneStep/PhoneStep'))
const PhoneStepResponsive = lazy(() => import('../../steps/PhoneStepResponsive/PhoneStepResponsive'))
const DefaultStep = lazy(() => import('../../steps/DefaultStep/DefaultStep'))
const SignContractStep = lazy(() => import('../../steps/SignContractStep/SignContractStep'))
const InferFromPreviousTrainSubmission = lazy(() => import('../../steps/InferFromPreviousTrainSubmission/InferFromPreviousTrainSubmission'))
const PhoneStepNumeric = lazy(() => import('../../steps/PhoneStepNumeric/PhoneStepNumeric'))
const InferTrainSubmissionFromOfflineStorage = lazy(() => import('../../steps/InferTrainSubmissionFromOfflineStorage/InferTrainSubmissionFromOfflineStorage'))
const InferFromPreviousSubmissionStep = lazy(() => import('../../steps/InferFromPreviousSubmissionStep/InferFromPreviousSubmissionStep'))
const StripePaymentStep = lazy(() => import('../../steps/StripePaymentStep/StripePayment'))
const MultipleSelectStep = lazy(() => import('../../steps/MultipleSelectStep/MultipleSelectStep'))
const StripeCardAndApplePayStepComponent = lazy(() => import('../../steps/StripeCardAndApplePayStep/StripeCardAndApplePayStep'))
const CurrencyStep = lazy(() => import('../../steps/CurrencyStep/CurrencyStep'))
const RadioCardsStep = lazy(() => import('../../steps/RadioCardsStep/RadioCardsStep'))
const SignContractStepResponsive = lazy(() => import('../../steps/SignContractStepResponsive/SignContractStepResponsive'))
const ResponsiveDateTimeStep = lazy(() => import('../../steps/ResponsiveDateTimeStep/ResponsiveDateTimeStep'))
const TrainsGuestOverview = lazy(() => import('../../steps/TrainsGuestOverview/TrainsGuestOverview'))
const NumberStep = lazy(() => import('../../steps/NumberStep/NumberStep'))
const DateFieldsStep = lazy(() => import('../../steps/DateFieldsStep/DateFieldsStep'))

type Props = {
  document: WorkflowStepResponseDocument, 
  workflowResultId: string,
  onFinish: OnFinish,
  pushStep: PushStep,
  options: WorkFlowStepContentOptions,
  location: any,
  match: any,
  scope: string[]
}

const WorkflowStepContent: React.FC<Props> = (props) => {

  const workflowDocument = _get(props, 'document')
  const workflowResultId = _get(props, 'workflowResultId')
  const onFinish = props.onFinish
  const pushStep = props.pushStep
  const options = props.options
  const location = props.location
  const match = props.match
  const scope = props.scope

  const childProps = {
    document: workflowDocument,
    onFinish,
    workflowResultId,
    pushStep,
    options,
    location,
    match,
    scope
  }

  const steps = _get(workflowDocument, 'data.attributes.step.steps')
  const input = _get(workflowDocument, 'data.attributes.step.input')

  if (steps) {
    switch (input) {
      default:
        return <LoopStep {...childProps} />
    }
  }


  switch (input) {
    case 'phone': {
      return <PhoneStep {...childProps} />
    }
    case 'phoneResponsive': {
      return <PhoneStepResponsive {...childProps} />
    }
    case 'fullName': {
      return <FullNameStep {...childProps} />
    }
    case 'email': {
      return <EmailStep {...childProps} />
    }
    case 'datetime': {
      return <DateTimeStep {...childProps} defaultDisplayFormat="MMM DD, YYYY HH:mm" />
    }
    case 'dateFields': {
      return <DateFieldsStep {...childProps} />
    }
    case 'signDocument': {
      return <SignContractStep {...childProps} />
    }
    case 'signDocumentResponsive': {
      return <SignContractStepResponsive {...childProps} />
    }
    case 'time': {
      return <DateTimeStep {...childProps} defaultDisplayFormat="HH:mm" />
    }
    case 'date': {
      return <DateTimeStep {...childProps} defaultDisplayFormat="MMM DD, YYYY" />
    }
    case 'dateResponsive': {
      return <ResponsiveDateTimeStep {...childProps} defaultDisplayFormat="MMM DD, YYYY" />
    }
    case 'inferFromPreviousSubmission': {
      return <InferFromPreviousSubmissionStep {...childProps} />
    }
    case 'inferFromPreviousTrainSubmission': {
      return <InferFromPreviousTrainSubmission {...childProps} />
    }
    case 'inferTrainSubmissionFromOfflineStorage': {
      return <InferTrainSubmissionFromOfflineStorage {...childProps} />
    }
    case 'phoneNumeric': {
      return <PhoneStepNumeric  {...childProps} />
    }
    case 'multipleSelect': {
      return <MultipleSelectStep {...childProps} />
    }
    case 'radioCards': {
      return <RadioCardsStep {...childProps} />
    }
    case 'stripe': {
      return <StripePaymentStep {...childProps} />
    }
    case 'buttons':
    case 'buttonsStep': {
      return <ButtonsStep {...childProps} />
    }
    case 'currency': {
      return <CurrencyStep {...childProps} />
    }
    case 'stripeCardAndApplePay': {
      return <StripeCardAndApplePayStepComponent {...childProps} />
    }
    case 'trainsGuestOverview': {
      return <TrainsGuestOverview {...childProps} />
    }
    case 'number': {
      return <NumberStep {...props} />
    }
    default: {
      if (typeof _get(workflowDocument, 'data.attributes.step.redirect') === 'string') {
        const redirectStep = _get(workflowDocument, 'data.attributes.step.redirect')
        const id = _get(props, 'match.params.id')
        const variation = _get(props, 'match.params.variation')
        const newUrl = ROUTES.WORKFLOW_STEP
          .replace(':variation', variation)
          .replace(':id', id)
          .replace(':step', redirectStep)
        return <Redirect to={newUrl} push={!!_get(workflowDocument, 'data.attributes.step.push')} />
      } else if (Array.isArray(_get(workflowDocument, 'data.attributes.step.buttons'))) {
        return <ButtonsStep {...childProps} />
      } else {
        return <DefaultStep {...childProps} />
      }
    }
  }
};

export default WorkflowStepContent;
