import React from 'react';
import clsx from 'clsx';
import _get from 'lodash/get';
import WorkflowStepButton from '../../../types/workflow-step-button';
import styles from './WorkflowButton.module.css'
import { IonButton } from '@ionic/react';
import Markdown from 'markdown-to-jsx'

type IonButtonProps = React.ComponentProps<typeof IonButton>

const createLabelFromShortcut = (shortcut: undefined | string | string[]) => {
  if (typeof shortcut === 'string') return shortcut
  else if (Array.isArray(shortcut)) return shortcut.join(', ')
  else return ''
}

type Props = {
  button: WorkflowStepButton
  onClick: (btn: WorkflowStepButton) => any,
  hideNonSkippableButtons?: boolean,
  disableNonSkippableButtons?: boolean,
  ionButtonProps?: IonButtonProps,
  icon?: React.ReactNode,
  iconOnly?: boolean
}

const WorkflowButton: React.FC<Props> = (props) => {
  let { button: btn } = props

  const isSkippable = !!btn.skip

  const className = clsx(
    (props.hideNonSkippableButtons && !isSkippable) ? styles.hidden : '',
    styles.btn,
    'button',
    'step-button-' + btn.key)

  let fill = _get(btn, 'fill')
  if (!['clear', 'outline', 'default', 'solid', undefined].includes(fill)) fill = undefined

  const markdown = '' + (btn.title || '') // this could be a non string value possibly

  const onClick = () => {
    console.log('on click')
    props.onClick(btn)
  }

  return (
    <IonButton
      {...props.ionButtonProps}
      className={className}
      fill={fill}
      title={createLabelFromShortcut(btn.shortcut)}
      disabled={props.disableNonSkippableButtons && !isSkippable}
      color={btn.color || (btn.default ? 'primary' : 'white')}
      onClick={onClick}>
      {props.iconOnly
        ? props.icon
        : <>
          {props.icon}
          <Markdown>
            {markdown}
          </Markdown>
        </>}
    </IonButton>
  )
};

export default WorkflowButton;
