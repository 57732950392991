import React from 'react';
import { IonCard, IonText, IonCardHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/react'
import clsx from 'clsx';
import styles from './Card.module.css'
import Markdown from 'markdown-to-jsx'
import CardFooter from '../CardFooter/CardFooter';

interface ContainerProps {
  className?: string,
  footer?: React.ReactNode,
  toolbar?: {
    buttonsLeft: React.ReactNode,
    buttonsRight: React.ReactNode,
    title: React.ReactNode
  }
}

const Card: React.FC<ContainerProps> = props => {
  return (
    <div className={clsx(styles.container, props.className)}>
      <IonCard className={clsx('workflow-card')}>
        {props.toolbar && <IonToolbar className={styles.toolbar}>
          {props.toolbar.buttonsLeft && <IonButtons slot="secondary">
            {props.toolbar.buttonsLeft}
          </IonButtons>}
          {props.toolbar.buttonsRight && <IonButtons slot="primary">
            {props.toolbar.buttonsRight}
          </IonButtons>}
          <IonTitle>{props.toolbar.title}</IonTitle>
        </IonToolbar>}
        {props.children}
      </IonCard>
      <CardFooter>
        {props.footer}
      </CardFooter>
    </div>
  );
};

export default Card;
