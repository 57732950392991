import { useState, useEffect } from "react"
import { DISABLE_DARK_MODE } from "../constants/ui";

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(isDark: boolean) {
  document.body.classList.toggle('dark', isDark);
}

const useIsDark = () => {
  const [isDark, setIsDark] = useState(prefersDark.matches)
  
  const toggleDarkTheme = () => {
    setIsDark(prefersDark.matches)
  }
  
  useEffect(() => {
    prefersDark.addListener(toggleDarkTheme);
    return () => {
      prefersDark.removeListener(toggleDarkTheme)
    }
  }, [])

  return isDark && !DISABLE_DARK_MODE
}

export default useIsDark
