import clsx from 'clsx';
import React from 'react';
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom';

const RawLink: React.FC<{ external?: boolean, download?: boolean } & LinkProps> = props => {
  const style = {
    textDecoration: 'none',
    color: 'inherit',
    ...props.style
  }

  const location = useLocation()

  const pathMatches = matchPath(location.pathname, props.to.toString())

  const className = clsx(props.className, pathMatches ? 'active' : 'not-active', pathMatches?.isExact ? 'exact' : 'not-exact')

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.to === location.pathname) {
      event.preventDefault();
    }

    if (props.onClick) {
      props.onClick(event);
    }
  }

  const { external, download, to, children, ...rest } = props

  if (props.to.toString().startsWith('tel:')) {
    return (
      <a className={className} onClick={onClick} href={props.to.toString()} style={style} {...rest}>{children}</a>
    )
  } else if (props.to.toString().startsWith('mailto:')) {
    return (
      <a className={className} onClick={onClick} rel="noopener noreferrer" target="_blank" href={props.to.toString()} style={style} {...rest}>{children}</a>
    )
  } else if (props.external) {
    return (
      <a className={className} onClick={onClick} rel="noopener noreferrer" target="_blank" download={download} href={props.to.toString()} style={style} {...rest}>{children}</a>
    )
  } else {
    return (
      <Link className={className} to={to} onClick={onClick} {...rest} style={style}>
        {children}
      </Link >
    )
  }
}

export default RawLink