import React from 'react'
import { IonText } from '@ionic/react';

type Props = {

}

const CardFooter: React.FC<Props> = props => {
  return (
    <IonText className="card-footer">
      {props.children}
    </IonText>
  )
}

export default CardFooter
