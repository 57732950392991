import React from 'react';
import { LoggerContext, defaultLoggerContext } from '../contexts/logger';

export function withLogger<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithLogger = (props: P) => {
    return <LoggerContext.Provider value={defaultLoggerContext}>
      <WrappedComponent  {...props} />
    </LoggerContext.Provider>
  };
  return ComponentWithLogger;
}

