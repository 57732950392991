import React from 'react'
import { IonApp } from '@ionic/react';

export default function withIonApp<P>(
  WrappedComponent: React.ComponentType<P>
) {
  const ComponentWithApp = (props: P) => {
    return (
      <IonApp>
        <WrappedComponent {...props} />
      </IonApp>
    )
  };
  return ComponentWithApp;
}

